import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { setBaseUrl } from '../../config'
import { useNavigate } from 'react-router-dom'

const CheckOldOperator = ({adhaarChecked, setAdhaarChecked}) => {
  

    // Check Operator
    // let  id = '0eae3514-f88c-4f69-a7cf-8a31a82f1d9b'
    const [adhaarNumberInput, setAdhaarNumberInput] = useState(null)
    const [oldOperator, setOldOperator] = useState(false)
    const [searchSuggetions, setSearchSuggetions] = useState([]);
    const [searchData, setSearchData] = useState([])
    const [showAdhaar, setShowAdhaar] = useState(false)
    const [id, setId] = useState({})
    const navigate = useNavigate()
  // Search Adhaar
  const getSearchData = async () => {
    try {
      const { data } = await axios.get(`${setBaseUrl}/operator/search`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
        },
      });
      let dataArryUserId = []
      let aadharArr = Object.keys(data["aadhar"])
      for (let i = 0; i < aadharArr.length ; i++) {
        dataArryUserId.push({ 
          [aadharArr[i]] : data["aadhar"][aadharArr[i]]
        })
      }
      setSearchData(() => dataArryUserId)
      // setId(dataArryUserId)
      setSearchSuggetions(() => dataArryUserId);
      
    } catch (error) {
      
    }
  };

  useEffect(() => {
    getSearchData();
  }, []);


 
// console.log(searchSuggetions)


    const handleCheckAdhaar = (e) => {
        e.preventDefault();
        setShowAdhaar(true)
      setAdhaarNumberInput(e.target.value)

      let dataArray = []
      for(let i = 0; i < searchData.length; i++) {
          if(Object.keys(searchData[i])[0].indexOf(e.target.value) !== -1)  {
              dataArray.push(searchData[i])
            }
          }
          
          // console.log(dataArray)
      if (e.target.value) { 
        setSearchSuggetions(dataArray)
        setOldOperator(true)
      } else {
        setOldOperator(false)
      }
      
      if(e.target.value!== searchSuggetions) {
        setOldOperator(false)
      }
      let aadhar_no_value = e.target.value;
     
      var expr =
        /^([0-9]{4}[0-9]{4}[0-9]{4}$)|([0-9]{4}\s[0-9]{4}\s[0-9]{4}$)|([0-9]{4}-[0-9]{4}-[0-9]{4}$)/;
      if (expr.test(aadhar_no_value)) {
        setAdhaarNumberInput(null);
        
      } else {
       
      }
    }


     
const handleCreate = () => {

  if(adhaarNumberInput !== null) {
    setOldOperator(true)
    navigate(`/operators/updateOperator/${id}`)
  } else {
    setOldOperator(false)
    setAdhaarChecked(true)
  }
}
     

  return (
    <>
     <div className="items-center flex  justify-center ">
      <form 
        className="border w-[400px] h-[500px] 
        items-center flex flex-col justify-center space-y-5 " 
        onChange={handleCheckAdhaar}>
      <h1 className="text-center absolute top-10 font-bold 
      text-slate-700 dark:text-white">
        Operators
        </h1>
      <span> Please Enter Adhaar No</span>
        <input 
        type="text"
        placeholder="Enter Valid Aadhaar no."
        className="bg-transparent
        rounded-full w-[280px]
        transition-all duration-300 
        ease-in-out focus:border-black border-black border 
        focus:outline-none focus:ring-0"
        value={adhaarNumberInput}
        />
        {adhaarNumberInput !== null && (
                <p className="text-[#24a062] font-bold mt-4 text-xs">{adhaarNumberInput}</p>
              )}
        <div className="flex justify-between gap-5">
        <button className="w-[120px] border border-black h-8 rounded-full">Back</button>
        <button className="w-[120px] bg-black text-white h-8 rounded-full" onClick={handleCreate}>{oldOperator? "Rejoin":"Create New"}</button>
        </div> 


      </form>
        <div className="flex flex-col absolute bottom-5 h-[100px] overflow-y-auto">

        {searchSuggetions?.map((item, i) => (
            <>
            <div className="" key={i}  onClick={() => {setAdhaarNumberInput(Object.keys(item)[0]); setId(item[Object.keys(item)[0]]);  setOldOperator(true)}}>
                {Object.keys(item)}
            </div>
            </>
        ))}
        </div>
     </div>


      </>
  )
}

export default CheckOldOperator
