import React from 'react'

const AssetSearch = ({setFilter, searchValue, handleSearch, handleSearchClick}) => {
  return (
    <div >
        <div>
         <div className=" w-fit flex items-center mx-2 rounded-2xl text-xs bg-white  dark:bg-slate-600 dark:text-white shadow-xl">
                <select className="flex-shrink-0 z-10 inline-flex items-center h-9 bg-white dark:bg-slate-600 dark:text-white rounded-s-2xl text-xs border-none focus:outline-none focus:ring-0"
                  onChange={(e) => setFilter(e.target.value)}
                >
                          {/* <option value="all">All Categories</option> */}
                          <option value="assetnumber">Asset Number</option>
                          <option value="category">Category</option>
                          <option value="yom">YOM</option>
                </select>
                <button className="w-4 h-7  items-center ml-2 text-center ">
                        <svg className="w-4 h-4  text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                        </svg>
                </button>
                  <input 
                  type="text" 
                  placeholder={
                    searchValue === "" ? "Search" : searchValue
                  }
                  className="w-[150px] h-9  border-none focus:outline-none bg-transparent rounded-e-2xl focus:ring-0 focus:border-none text-xs" 
                  onChange={handleSearch}
                  onClick={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault(); // Prevent form submission
                      handleSearchClick(searchValue);
                    }
                  }}
                  />

              </div>
    </div>
    </div>
  )
}

export default AssetSearch
