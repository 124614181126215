// import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { PermissionContext } from "../../Context/PermissionsContext";
// import { useContext } from "react";
// import useAuthorities from "../../utils/useAuthorities";

// function NewAssetBtn({ tabName }) {
//   const [perms, setPerms] = useContext(PermissionContext);
//   const [isDisabled, setIsDisabled] = useState(true);
//   const [buttonText, setButtonText] = useState("");
//   const [buttonLink, setButtonLink] = useState("");
//   const {
//     createAssetPerm,
//     createLeasePerm,
//     createMaintenancePerm,
//     createOperatorPerm,
//   } = useAuthorities();
//   useEffect(() => {
//     // console.log(perms);
//   });

//   const getButtonText = () => {
//     switch (tabName) {
//       case "asset":
//         setButtonText("New Asset");
//         setButtonLink("/assets/newAsset");
//         //if the button is a child of asset component only then we need to check
//         if (createAssetPerm) {
//           setIsDisabled(false);
//         }
//         break;
//       case "maintenance":
//         setButtonText("New Maintenance");
//         setButtonLink("/maintenance/new-maintenance");
//         if (createMaintenancePerm) {
//           setIsDisabled(false);
//         }
//         break;
//       case "lease":
//         setButtonText("New Lease");
//         setButtonLink("/lease/newLease");
//         if (createLeasePerm) {
//           setIsDisabled(false);
//         }
//         break;
//       case "operator":
//         setButtonText("New Operator");
//         setButtonLink("/operators/newOperator");
//         if (createOperatorPerm) {
//           setIsDisabled(false);
//         }
//         break;
//       default:
//         setButtonText("New Asset");
//         break;
//     }
//   };

//   useEffect(() => {
//     getButtonText();
//   });

//   const navigate = useNavigate();
//   return (
//     <button
//       onClick={() => navigate(buttonLink)}
//       // className={`button text-white bg-indigo-900 dark:bg-indigo-500 hover:bg-indigo-600 hover:dark:bg-indigo-800 py-2 m-2 px-2 w-28 rounded-md shadow-md mx-2 text-xs sm:text-sm cursor-pointer ${
//       //   isDisabled ? "bg-gray-400" : ""
//       // }`}
//       className={`m-2 items-center justify-center bg-blue-600 dark:bg-indigo-500 dark:hover:bg-indigo-900 hover:bg-blue-900 text-white py-2 px-2 rounded-lg shadow-md transition duration-300 ease-in-out transform hover:scale-105 md:py-1 text-sm cursor-pointer
//         ${isDisabled ? 'bg-gray-400' : ''}
//       `}
//       disabled={isDisabled}
//     >
//       <span className="m-1 p-0">+</span>
      
//        {buttonText}
//     </button>
//   );
// }

// export default NewAssetBtn;
import React from "react";
import { useNavigate } from "react-router-dom";
import { PermissionContext } from "../../Context/PermissionsContext";
import { useContext, useEffect, useState } from "react";
import useAuthorities from "../../utils/useAuthorities";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

function NewAssetBtn({ tabName }) {
  // const [perms, setPerms] = useContext(PermissionContext);
  const [, ] = useContext(PermissionContext);
  const [isDisabled, setIsDisabled] = useState(true);
  const [buttonText, setButtonText] = useState("");
  const [buttonLink, setButtonLink] = useState("");
  const {
    createAssetPerm,
    createLeasePerm,
    createMaintenancePerm,
    createOperatorPerm,
  } = useAuthorities();

  useEffect(() => {
    getButtonText();
  });

  const getButtonText = () => {
    switch (tabName) {
      case "asset":
        setButtonText("New Asset");
        setButtonLink("/assets/newAsset");
        if (createAssetPerm) {
          setIsDisabled(false);
        }
        break;
      case "maintenance":
        setButtonText("New Maintenance");
        setButtonLink("/maintenance/new-maintenance");
        if (createMaintenancePerm) {
          setIsDisabled(false);
        }
        break;
      case "lease":
        setButtonText("New Lease");
        setButtonLink("/lease/newLease");
        if (createLeasePerm) {
          setIsDisabled(false);
        }
        break;
      case "operator":
        setButtonText("New Operator");
        setButtonLink("/operators/newOperator");
        if (createOperatorPerm) {
          setIsDisabled(false);
        }
        break;
      default:
        setButtonText("New Asset");
        break;
    }
  };

  const navigate = useNavigate();
  return (
    <button
      onClick={() => navigate(buttonLink)}
      className={`text-xs flex justify-start  items-center  w-full h-10 text-black border-b  ${
        isDisabled ? "bg-gray-400 cursor-not-allowed" : ""
      }`}
     
      disabled={isDisabled}
    >
      {/* <span className="mr-2 p-1"></span> */}
    <AddCircleOutlineIcon className="mx-1 h-2 w-2 p-1"/>
      {buttonText}
    </button>
  );
}

export default NewAssetBtn;


