import React, { useEffect, useState } from "react";

function BasicDetails({ assetBasicDetails, setAssetBasicDetails }) {
  const categories = [
    "Articulating Boom",
    "Telescopic Boom",
    "Truck Mounted Boom",
    "Diesel Scissors",
    "VTL",
    "Runabout",
    "Battery Scissors",
  ];

  const handleSelectChange = (e) => {
    setAssetBasicDetails({
      ...assetBasicDetails,
      category: e.target.value,
    });
  };
  useEffect(() => {
    setAssetBasicDetails({
      ...assetBasicDetails,
      category: categories[0],
    });
    console.log(assetBasicDetails);
  }, []);
  return (
    <>
        <button className="bg-black text-white mx-6 my-2 text-xs  w-[100px] h-7 rounded-full">Basic Details</button>
      <div className="p-10 text-xs gap-4  rounded-3xl border bg-white dark:bg-slate-900 grid grid-cols-1 md:grid-cols-4 ">
        {/*Asset No  */}
        <div className="flex-1 w-[200px] ">
          <label className="font-bold">Asset No</label>
          <input
            type="text"
            value={assetBasicDetails?.asset_no}
            placeholder="Asset No"
            class="w-[200px] h-8 rounded border-gray-200
                focus:outline-none
                focus:border-gray-200 focus:ring-0
                text-xs"
            onChange={(e) =>
              setAssetBasicDetails({
                ...assetBasicDetails,
                asset_no: e.target.value,
              })
            }
          />
        </div>
        {/* Make */}
        <div className="flex-1 w-[200px] ">
          <label className="font-bold">Make</label>
          <input
            type="text"
            value={assetBasicDetails?.make}
            placeholder="Make"
            class="w-[200px] h-8 rounded border-gray-200
                focus:outline-none
                focus:border-gray-200 focus:ring-0
                text-xs"
            onChange={(e) =>
              setAssetBasicDetails({
                ...assetBasicDetails,
                make: e.target.value,
              })
            }
          />
        </div>
        {/* Model */}
        <div className="flex-1 w-[200px] ">
          <label className="font-bold">Model</label>
          <input
            type="text"
            value={assetBasicDetails?.model}
            placeholder="Model"
            onChange={(e) =>
              setAssetBasicDetails({
                ...assetBasicDetails,
                model: e.target.value,
              })
            }
            class="w-[200px] h-8 rounded border-gray-200
                    focus:outline-none
                    focus:border-gray-200 focus:ring-0
                    text-xs"
          />
        </div>
        {/* Description */}
        <div className="flex-1 w-[200px] ">
          <label className="font-bold">Description</label>
          <textarea
            rows="2"
            value={assetBasicDetails?.description}
            placeholder="Say something that best describes the asset"
            onChange={(e) =>
              setAssetBasicDetails({
                ...assetBasicDetails,
                description: e.target.value,
              })
            }
            class="w-[200px] h-8 rounded border-gray-200
                focus:outline-none
                focus:border-gray-200 focus:ring-0
                text-xs"
          />
        </div>
        {/* Serial No */}
        <div className="flex-1 w-[200px] ">
          <label className="font-bold">Serial No</label>
          <input
            type="text"
            value={assetBasicDetails?.serial_no}
            placeholder="Serial No"
            onChange={(e) =>
              setAssetBasicDetails({
                ...assetBasicDetails,
                serial_no: e.target.value,
              })
            }
            class="w-[200px] h-8 rounded border-gray-200
                    focus:outline-none
                    focus:border-gray-200 focus:ring-0
                    text-xs"
          />
        </div>
        {/* Purchased from */}
        <div className="flex-1 w-[200px] ">
          <label className="font-bold">Purchased from</label>
          <input
            type="text"
            value={assetBasicDetails?.purchased_from}
            placeholder="Serial No"
            onChange={(e) =>
              setAssetBasicDetails({
                ...assetBasicDetails,
                purchased_from: e.target.value,
              })
            }
            class="w-[200px] h-8 rounded border-gray-200
                    focus:outline-none
                    focus:border-gray-200 focus:ring-0
                    text-xs"
          />
        </div>
        {/* RFID */}
        <div className="flex-1 w-[200px] ">
          <label className="font-bold">RFID</label>
          <input
            type="text"
            value={assetBasicDetails?.rfid}
            placeholder="RFID Not Required"
            onChange={(e) =>
              setAssetBasicDetails({
                ...assetBasicDetails,
                rfid: e.target.value,
              })
            }
            disabled={true}
            class="w-[200px] h-8 rounded border-gray-200
                    focus:outline-none
                    focus:border-gray-200 focus:ring-0
                    text-xs"
          />
        </div>

        {/* Device No */}
        <div className="flex-1 w-[200px] ">
          <label className="font-bold">Device No</label>
          <input
            type="text"
            value={assetBasicDetails?.device_no}
            placeholder="Device Hash Not Required"
            onChange={(e) =>
              setAssetBasicDetails({
                ...assetBasicDetails,
                device_no: e.target.value,
              })
            }
            class="w-[200px] h-8 rounded border-gray-200
                    focus:outline-none
                    focus:border-gray-200 focus:ring-0
                    text-xs"
          />
        </div>
        {/* YOM */}
        <div className="flex-1 w-[200px] ">
          <label className="font-bold">Year of Manufacturing</label>
          <input
            type="text"
            value={assetBasicDetails?.yom}
            placeholder="Year of Manufacturing"
            onChange={(e) =>
              setAssetBasicDetails({
                ...assetBasicDetails,
                yom: e.target.value,
              })
            }
            class="w-[200px] h-8 rounded border-gray-200
                    focus:outline-none
                    focus:border-gray-200 focus:ring-0
                    text-xs"
          />
        </div>
        {/* Site Location */}
        <div className="flex-1 w-[200px] ">
          <label className="font-bold">Site Location</label>
          <input
            type="text"
            value={assetBasicDetails?.site_location}
            placeholder="Site Location"
            required
            onChange={(e) =>
              setAssetBasicDetails({
                ...assetBasicDetails,
                site_location: e.target.value,
              })
            }
            class="w-[200px] h-8 rounded border-gray-200
                    focus:outline-none
                    focus:border-gray-200 focus:ring-0
                    text-xs"
          />
        </div>
        {/* Height of Machine */}
        <div className="flex-1 w-[200px] ">
          <label className="font-bold">Height of Machine</label>
          <input
            type="text"
            value={assetBasicDetails?.hieght_machine}
            placeholder="e.g. 10 ft"
            onChange={(e) =>
              setAssetBasicDetails({
                ...assetBasicDetails,
                hieght_machine: e.target.value,
              })
            }
            class="w-[200px] h-8 rounded border-gray-200
                    focus:outline-none
                    focus:border-gray-200 focus:ring-0
                    text-xs"
          />
        </div>
        {/* Capacity of machine */}
        <div className="flex-1 w-[200px] ">
          <label className="font-bold">Capacity of Machine</label>
          <input
            type="text"
            value={assetBasicDetails?.capacity}
            placeholder="e.g. 10 ft"
            onChange={(e) =>
              setAssetBasicDetails({
                ...assetBasicDetails,
                capacity: e.target.value,
              })
            }
            class="w-[200px] h-8 rounded border-gray-200
                    focus:outline-none
                    focus:border-gray-200 focus:ring-0
                    text-xs"
          />
        </div>
        {/* Rating */}
        <div className="flex-1 w-[200px] ">
          <label className="font-bold">Ratings(out of 5)</label>
          <select
            type="text"
            value={assetBasicDetails?.rating }
            placeholder="e.g. 10 ft"
            onChange={(e) =>
              setAssetBasicDetails({
                ...assetBasicDetails,
                rating: e.target.value,
              })
            }
            class="w-[200px] h-8 rounded border-gray-200
                    focus:outline-none
                    focus:border-gray-200 focus:ring-0
                    text-xs"
          >
                <option>0</option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
          </select>
        </div>
        {/* Cateogry */}
        <div class="flex-1 w-[200px]">
              <label class="">Category*</label>
              <select
                // className="border-2 rounded-md p-2 text-xs text-black"
              className="w-[200px] h-8 rounded border-gray-200
                    focus:outline-none
                    focus:border-gray-200 focus:ring-0
                    text-xs"
                onChange={handleSelectChange}
              >

                {categories.map((category) => (
                  <option key={category} 
                  value={category} 
                  selected={assetBasicDetails?.category===category}>
                    {category}
                  </option>
                ))}
              </select>
            </div>
            {/* Location */}
            <div class="w-[200px] mt-3 ">
                <div class="flex-1 w-[200px] flex justify-between">
                  <label class="">Arrival Location 

                    <span className="text-red-500 text-[15px]">*</span>
                  </label>
                  <button
                    id="open-btn"
                    onclick="openModal('newLocation_modal');"
                    class="block bg-black  text-white text-xs rounded px-2 py-1 cursor-pointer"
                    type="button"
                  >
                    +New
                  </button>
                </div>
                <select class="input w-full border mt-2 text-gray-500 text-xs border-slate-300 dark:bg-slate-900  font-medium rounded-md">
                  <option value="bb4eaffe-6d14-4589-8495-f7b76948e6dd">
                    {" "}
                    Pon Nagar, Parivakkam{" "}
                  </option>
                  <option value="b50d06eb-bd71-4a7d-bdd4-bd55288c5613">
                    {" "}
                    Shamshabad, Mamidpally{" "}
                  </option>
                  <option value="8db77f4e-5966-4e10-93f4-32fe7be72ed7">
                    {" "}
                    Airport (L&amp;T Gate){" "}
                  </option>
                  <option value="3b46f800-9cbf-44b0-a007-6c3ade64a3e3">
                    {" "}
                    Hyderabad{" "}
                  </option>
                  <option value="06822b0a-f80d-4232-a109-04c9c09121ce">
                    {" "}
                    Hyderabad{" "}
                  </option>
                </select>
              </div>
              <div className="flex-1 w-[200px]">
      <label className="font-bold">Machine Sold</label>
      <div className="flex gap-4">
        {/* Radio button for 'Yes' */}
        <label className="flex items-center">
          <input
            type="radio"
            name="is_sold"
            value={true}
            checked={assetBasicDetails.is_sold === true}
            onChange={() => setAssetBasicDetails({ ...assetBasicDetails, is_sold: true })}
            className="mr-2 focus:ring-0"
          />
          Yes
        </label>

        {/* Radio button for 'No' */}
        <label className="flex items-center">
          <input
            type="radio"
            name="is_sold"
            value={false}
            checked={assetBasicDetails.is_sold === false}
            onChange={() => setAssetBasicDetails({ ...assetBasicDetails, is_sold: false })}
            className="mr-2 focus:ring-0"
          />
          No
        </label>
      </div>
    </div>

      </div>
    </>
  );
}

export default BasicDetails;
