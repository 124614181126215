import React, { useState } from "react";

const sampleData = {
  months: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  rentalDays: Array(36)
    .fill(0)
    .map(() => Math.floor(Math.random() * 31) + 1),
  transportDays: Array(36)
    .fill(0)
    .map(() => Math.floor(Math.random() * 10)),
  yardDays: Array(36)
    .fill(0)
    .map(() => Math.floor(Math.random() * 5)),
  billingHours: Array(36)
    .fill(0)
    .map(() => Math.floor(Math.random() * 200)),
  normalWorkingTime: Array(36)
    .fill(0)
    .map(() => Math.floor(Math.random() * 160)),
  overtimeWorkingTime: Array(36)
    .fill(0)
    .map(() => Math.floor(Math.random() * 40)),
  billingAmount: Array(36)
    .fill(0)
    .map(() => Math.floor(Math.random() * 10000)),
  normalBilling: Array(36)
    .fill(0)
    .map(() => Math.floor(Math.random() * 8000)),
  overtimeBilling: Array(36)
    .fill(0)
    .map(() => Math.floor(Math.random() * 2000)),
  spares: Array(36)
    .fill(0)
    .map(() => Math.floor(Math.random() * 50)),
  operators: Array(36)
    .fill(0)
    .map(() => Math.floor(Math.random() * 20)),
  transport: Array(36)
    .fill(0)
    .map(() => Math.floor(Math.random() * 300)),
  miscellaneous: Array(36)
    .fill(0)
    .map(() => Math.floor(Math.random() * 100)),
};

const AssetDashboards = () => {
  // State to manage which headers are open
  const [openHeaders, setOpenHeaders] = useState({
    2022: false,
    2023: false,
    2024: true, // Example: Open by default
  });

  // Function to toggle the subheader visibility
  const toggleHeader = (year) => {
    setOpenHeaders((prev) => ({
      ...prev,
      [year]: !prev[year],
    }));
  };

  const Years = ["2020", "2021", "2022", "2023", "2024"];

  const [open, setOpen] = useState(false);
  const [openYear, setOpenYear] = useState(false)

  return (
    <>
    
    <div className="md:w-3/4 overflow-y-auto text-xs p-4 border rounded-2xl  overflow-x-auto">
              
              <table className="border border-separate rounded-2xl w-fit border-spacing-1">
                <thead className="font-bold border rounded border-black">
                  <tr>
                    <th>Data</th>
                    <th>
                      Year
                      <tr>
                        {Years.map((year, index) => (
                          <>
                            <td className="border" key={index}>
                              <span className="" onClick={() => toggleHeader(year)}>{year}</span>
                              {openYear && 
                              <>
                              <tr className="">
                              {openHeaders[year] && 
                                <>
                                {sampleData.months.map((month) => (
                                  <>
                                    <td className="" key={month}>
                                      {month}
                                    </td>
                                  </>
                                ))}
                                </>
                              } 
                              </tr>
                              
                              </>
                              }
                            </td>
                          </>
                        ))}
                      </tr>
                    </th>
                  </tr>
                </thead>
               <tbody>
                        
               </tbody>
              </table>
            </div>
     
    </>
  );
};

export default AssetDashboards;
