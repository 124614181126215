import axios from "axios";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { setBaseUrl } from "../../../config";
import { TableLoader } from "../../TableLoader";
import ReactPaginate from "react-paginate";
import { CurrentItems } from "./CurrentItems";
import { Pagination } from "../Pagination";
import NewAssetBtn from "../../Buttons/NewAssetBtn";
import HistoryBtn from "../../Buttons/HistoryBtn";
import ExcelReportBtn from "../../Buttons/ExcelReportBtn";
import MISReportBtn from "../../Buttons/MISReportBtn";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { CiFilter } from "react-icons/ci";
import { PermissionContext } from "../../../Context/PermissionsContext";
import { IoMdAdd } from "react-icons/io";
import Loader from "../../Loader";
import { useSearchData } from "../../../hooks/useSearchData";
import { useLocation, useParams } from "react-router-dom";
import { IoIosClose } from "react-icons/io";
import GlobalSearch from "../../GlobalSearch";
import { useSearchAssets } from "../../../hooks/useSearchAssets";
import AssetSearch from "../../Search/AssetSearch";
import { capitalizeFirstLetter } from "../../Modals/AssetDetails/Tabs/FormatDate";
import { CiViewTable } from "react-icons/ci";

function AssetTable({ showCurrentTab, setShowCurrentTab }) {
  const [perms, setPerms] = useContext(PermissionContext);

  // const [itemOffset, setItemOffset] = useState(0);
  // const [items, setItems] = useState(1);
  const {
    setFilter,
    searchValue,
    handleSearch,
    handleSearchClick,
    showSearchDiv,
    loader,
    setLoader,
    deleted,
    setDeleted,
    assetDetails,
    getAllAssets,

    showAllData,
    getSearchList,
    itemOffset,
    setItemOffset,
  } = useSearchAssets(setShowCurrentTab);

  let selected = [
    "all Assets",
    "active",
    "inactive",
    "never assigned",
    "expiring",
    "expired",
  ];
  const [selectedItem, setSelectedItem] = useState("all Assets");
  const [openFilter, setOpenFilter] = useState(false);
  const [openTable, setopenTable] = useState(false);
  const [openFilterStatus, setFilterStatus] = useState(false);
  const [openYom, setOpenYom] = useState(false);
  const [openCategory, setOpenCategory] = useState(false);
  const [openSold, setOpenSold] = useState(null);
  const uniqueYears = [...new Set(assetDetails.map((details) => details.yom))];
  const uniqueCategory = [
    ...new Set(assetDetails.map((details) => details.category)),
  ];
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  let tableArray = [10, 50, 100, 150];
  const [selectTableData, setSelectTableData] = useState(null);
  // const [ascending, setAscending] = useState(null);
  // const [descending, setDescending] = useState(null);
  const [sort, setSort] = useState({ascending: null, descending: null})
  const [device, setDevice] = useState('all device')

  return (
    <>
      <div className="px-2 flex  flex-col md:flex-row border-b  pb-2 justify-between items-center md:space-y-0">
        <div className="flex gap-4 text-xs justify-between">
          <button
            className="border rounded-lg h-6 w-16 justify-center cursor-pointer items-center flex gap-1"
            onClick={() => setopenTable(true)}
          >
            <CiViewTable />
            table
          </button>

          {selectTableData && (
            <>
              <button className="bg-[#EBEBEB] px-2 flex items-center rounded cursor-pointer">
                {selectTableData}
                <IoIosClose
                  className="h-4 w-4"
                  onClick={() => setSelectTableData(null)}
                />
              </button>
            </>
          )}
          <div
            className="border rounded-lg h-6 w-16 justify-center  items-center flex gap-1 cursor-pointer"
            onClick={() => setOpenFilter(true)}
          >
            <CiFilter />
            filter
          </div>

          {selectedItem !== "all Assets" && (
            <>
              <button className="bg-[#EBEBEB] px-2 flex items-center rounded">
                {selectedItem}
                <IoIosClose
                  className="h-4 w-4"
                  onClick={() => setSelectedItem("all Assets")}
                />
              </button>
            </>
          )}

          {selectedYear && (
            <>
              <button className="bg-[#EBEBEB] px-2 flex items-center rounded">
                {selectedYear}
                <IoIosClose
                  className="h-4 w-4"
                  onClick={() => setSelectedYear(null)}
                />
              </button>
            </>
          )}

          {selectedCategory && (
            <>
              <button className="bg-[#EBEBEB] px-2 flex items-center rounded">
                {selectedCategory}
                <IoIosClose
                  className="h-4 w-4"
                  onClick={() => setSelectedCategory(null)}
                />
              </button>
            </>
          )}

          {openSold && (
            <>
              <button className="bg-[#EBEBEB] px-2 flex items-center rounded">
                {openSold}
                <IoIosClose
                  className="h-4 w-4"
                  onClick={() => setOpenSold(null)}
                />
              </button>
            </>
          )}

          {sort.ascending && (
            <>
              <button className="bg-[#EBEBEB] px-2 flex items-center rounded">
                {sort.ascending}
                <IoIosClose
                  className="h-4 w-4"
                  onClick={() => setSort({ascending: null})}
                />
              </button>
            </>
          )} 

          {sort.descending && (
              <>
              <button className="bg-[#EBEBEB] px-2 flex items-center rounded">
                {sort.descending}
                <IoIosClose
                  className="h-4 w-4"
                  onClick={() => setSort({descending:null})}
                />
              </button>
            </>
          )}

          {device !== 'all device' && (
            <>
              <button className="bg-[#EBEBEB] px-2 flex items-center rounded">
                {device}
                <IoIosClose
                  className="h-4 w-4"
                  onClick={() => setDevice("all device")}
                />
              </button>
            </>
          )}

          {openTable && (
            <>
              <div
               
                className="absolute mt-10 z-[9999] left-0 text-xs
                flex flex-col justify-center 
               bg-white dark:bg-[#070e18] w-[80px] shadow-2xl rounded-lg h-fit items-center"
              >
                {tableArray.map((table) => (
                  <>
                    {" "}
                    <button
                      key={table}
                      className="hover:bg-[#EBEBEB] w-full  py-1 dark:hover:text-black "
                      onClick={() => setSelectTableData(table)}
                    >
                      {table}
                    </button>{" "}
                  </>
                ))}
              </div>
              <div 
              className="fixed top-0 left-0 bottom-0 right-0 bg-[#00000006] z-50 "
              onClick={() => setopenTable(false)}
              />
            </>
          )}
          {openFilter && (
            <>
              <div
                className="absolute mt-10 z-[9999] left-20
                  flex flex-col justify-center  pt-6 shadow-2xl
                 bg-white dark:bg-[#070e18]  w-[150px]  rounded-lg h-fit items-center"
              >
                <button
                  className="hover:bg-[#EBEBEB] w-full dark:hover:text-black py-2"
                  onClick={() => setFilterStatus(!openFilterStatus)}
                >
                  Status
                </button>
                <button
                  className="hover:bg-[#EBEBEB] w-full py-2 dark:hover:text-black"
                  onClick={() => setOpenYom(!openYom)}
                >
                  YOM
                </button>
                <button
                  className="hover:bg-[#EBEBEB] w-full py-2 dark:hover:text-black"
                  onClick={() => setOpenCategory(!openCategory)}
                >
                  Category
                </button>
                <button 
                  className="bg-[#EBEBEB]  w-full py-2 dark:hover:text-black"
                  disabled
                  onClick={() => {
                    setSort({ascending:"ascending"});
                  }}
                >
                  Ascending
                </button>
                <button 
                  className="bg-[#EBEBEB]  w-full py-2 dark:hover:text-black"
                  disabled
                  onClick={() => {
                    setSort({descending:"descending"});
                  }}
                >
                  Descending
                </button>
                <button
                  className="hover:bg-[#EBEBEB] w-full py-2 dark:hover:text-black"
                  onClick={() => {
                    setDevice("device installed");
                  }}
                >
                  Device Installed
                </button>
                <button
                  className="hover:bg-[#EBEBEB] w-full py-2 dark:hover:text-black"
                  onClick={() => {
                    setOpenSold("true");
                  }}
                >
                  Sold Machines
                </button>
                <button
                  className="flex items-center justify-start py-2 
      "
                >
                  <IoMdAdd />
                  filter
                </button>
              </div>

              {openFilterStatus && (
                <>
                  <div
                    onMouseLeave={() => setFilterStatus(false)}
                    className="absolute mt-9 z-[9999] left-[18.5%]
                    flex flex-col justify-center  pt-6 shadow-2xl
                  bg-white dark:bg-[#070e18] dark:border-none dark:shadow-md   dark:shadow-[#767676] w-[150px] border rounded-lg h-fit items-center"
                  >
                    {selected.map((select) => {
                      return (
                        <>
                          <button
                            className={`hover:bg-[#EBEBEB] dark:hover:text-black w-full  py-2 ${
                              selectedItem === select
                                ? "bg-[#b9b8b8] dark:text-black"
                                : ""
                            }  `}
                            onClick={() => setSelectedItem(select)}
                            key={select}
                          >
                            {capitalizeFirstLetter(select)}
                          </button>
                        </>
                      );
                    })}

                    <button
                      className="flex items-center justify-start py-2"
                    >
                      <IoMdAdd />
                      Status
                    </button>
                  </div>
                </>
              )}

              {openYom && (
                <>
                  <>
                    <div
                      onMouseLeave={() => setOpenYom(false)}
                      className="absolute mt-9 z-[9999] left-[18.5%]
                    flex flex-col justify-center  pt-6 shadow-2xl
                  bg-white dark:bg-[#070e18] dark:border-none dark:shadow-md   
                  dark:shadow-[#767676] w-[150px] border rounded-lg h-fit items-center"
                    >
                      {uniqueYears.map((year) => (
                        <button
                          key={year}
                          className="hover:bg-[#EBEBEB] dark:hover:text-black w-full py-2"
                          onClick={() => setSelectedYear(year)}
                        >
                          {year}
                        </button>
                      ))}
                    </div>
                  </>
                </>
              )}

              {openCategory && (
                <>
                  <>
                    <div
                      onMouseLeave={() => setOpenCategory(false)}
                      className="absolute mt-9 z-[9999] left-[18.5%]
                      flex flex-col justify-center  pt-6 shadow-2xl
                      bg-white dark:bg-[#070e18] dark:border-none dark:shadow-md   
                      dark:shadow-[#767676] w-[150px] border rounded-lg h-fit pb-6 items-center"
                    >
                      {uniqueCategory.map((category) => (
                        <button
                          key={category}
                          className="hover:bg-[#EBEBEB] dark:hover:text-black w-full py-2"
                          onClick={() => setSelectedCategory(category)}
                        >
                          {category}
                        </button>
                      ))}
                    </div>
                  </>
                </>
              )}

              <div
                className="fixed top-0 left-0 bottom-0 right-0 bg-[#00000006] z-50 "
                onClick={() => setOpenFilter(false)}
              />
            </>
          )}

          {/* <div className="">filtered data</div> */}
        </div>
        <div className="flex flex-col md:flex-row  justify-between items-center  md:space-y-0 md:space-x-4">
          <div className="items-center ">
            {/* <div> */}

            {/* Search Tab */}

            <AssetSearch
              setFilter={setFilter}
              searchValue={searchValue}
              handleSearch={handleSearch}
              handleSearchClick={handleSearchClick}
            />

            {showSearchDiv.length > 0 && (
              <div
                className="absolute bg-white
               dark:bg-gray-800 p-2
               border w-[350px] h-[50vh] 
               dark:border-gray-600 
               rounded z-10  
               overflow-y-scroll right-3 mt-5
               shadow transition-all duration-300 ease-in-out"
              >
                {/* Aconsole.log(showSearchDiv) */}
                {/* <button className="absolute right-0 text-xs font-bold mx-2  text-end">Close</button> */}
                {showSearchDiv.map((item) => (
                  <p
                    onClick={() => handleSearchClick(item)}
                    className="cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-700 rounded-md p-2 transition duration-300 ease-in-out"
                  >
                    {item}
                  </p>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      {loader ? (
        <Loader />
      ) : (
        <CurrentItems
          deleted={deleted}
          setDeleted={setDeleted}
          loader={loader}
          setLoader={setLoader}
          assetDetails={assetDetails}
          selected={selected}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          selectTableData={selectTableData}

          selectedCategory={selectedCategory}
          selectedYear={selectedYear}
          device={device}
          openSold={openSold}
        />
      )}
    </>
  );
}

export default AssetTable;
