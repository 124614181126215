import React from 'react'

const OperatorSearch = ({handleSearchCategory, searchValue, handleSearch, handleSearchClicik}) => {
  return (
    <>
<div>
                <div className=" w-fit flex items-center mx-2 rounded-2xl text-xs dark:bg-slate-600 bg-white shadow-xl">
                   <select className="flex-shrink-0 z-10  inline-flex items-center h-9 dark:bg-slate-600  rounded-s-2xl text-xs border-none focus:outline-none focus:ring-0"
                  onChange={(e) => handleSearchCategory(e.target.value)}
                  defaultValue={searchValue}
                >
                   {/* <option value="all">All Categories</option> */}
                        <option value="adhaarNo">Adhaar No.</option>
                        <option value="pf">PF A/c. No.</option>
                        <option value="name">Name</option>
                          
                </select>
                <button className="w-4 h-7  items-center ml-2 text-center ">
                        <svg className="w-4 h-4  text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                        </svg>
                </button>
                  <input 
                  type="text" 
                  placeholder="Search"
                  className="w-[150px] h-9 border border-none focus:outline-none bg-transparent rounded-e-2xl focus:ring-0 focus:border-none text-xs" 
                  value={searchValue}
                      onChange={handleSearch}
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          handleSearchClicik(event.target.value);
                        }
                      }}
                    
                  />

              </div>
    </div>  
    </>
  )
}

export default OperatorSearch
