import React, { useContext, useEffect, useState } from "react";
import logo from "../../assets/images/logo.png";
import profile from "../../assets/images/profile.png";
import { Link, useNavigate } from "react-router-dom";
import { NavbarContext } from "../../Context/NavbarContext";
import { PermissionContext } from "../../Context/PermissionsContext";
import GlobalSearch from "../GlobalSearch";
import { FaUser } from "react-icons/fa";
import { IoNotificationsOutline } from "react-icons/io5";
import { GiHamburgerMenu } from "react-icons/gi";

function Navbar({ tabNo, setTabNo, theme, setTheme, setOpen, open}) {
  const user = sessionStorage.getItem("user");
  const [perms, ] = useContext(PermissionContext);
  const [navState, ] = useContext(NavbarContext);
  const [, setOpenTab] = useState("");
  const [openUserLogo, setOpenUserLogo] = useState(false);
  const navigate = useNavigate();

  
  useEffect(() => {
    switch (navState) {
      case 1:
        setOpenTab("Dashboard");
        break;
      case 2:
        setOpenTab("Assets");
        break;
      case 3:
        setOpenTab("Maintenance");
        break;
      case 4:
        setOpenTab("Lease");
        break;
      case 5:
        setOpenTab("Telematics");
        break;
      case 6:
        setOpenTab("Operator");
        break;
      case 7:
        setOpenTab("Settings");
        break;
      
      default:
        break;
    }
  }, [navState]);

  const handleTheme = () => {
    if (sessionStorage.getItem("theme") === "dark") {
      console.log("theme changed to normal");
      setTheme("");
      sessionStorage.removeItem("theme");
    } else {
      console.log("theme changed to dark");
      setTheme("dark");
      sessionStorage.setItem("theme", "dark");
    }
  };


  const handleLogut = () => {
    sessionStorage.clear();
    navigate("/login");
  };

 

    return (
    <>
      <nav
        id=""
        className="h-10 shadow px-4 lg:px-8 ml-10 fixed top-0 flex w-full justify-between z-[9999] bg-white dark:bg-black"
      >
        <div className="flex justify-between">
          <div className=" navbar-border basis-3/12 lg:basis-2/12  md:block  md:pb-2  
                 ">
                   {/* border-r-2 border-white */}
          
           <div className="flex justify-start items-center  w-[300px]">
              <img className="w-8 mr-2 mb-1" src={logo} alt="logo" />
              <p className=" font-semibold   text-lg">
                Asset <span className="font-semibold">Tracker</span>
              </p>
            </div>

          </div>
          <div className=" flex justify-end basis-6/12 sm:basis-6/12 md:basis-6/12 lg:basis-7/12 text-right items-center  pb-2">
          
          {/* <GlobalSearch />   */}
            <div className="inline  pl-2 pr-1 md:px-5">
              <div className="flex items-center justify-center mx-auto  ">
                <div className="flex justify-end items-center auto fixed right-[8.2rem] top-3">

              


                  <div>
                    <input
                      type="checkbox"
                      name=""
                      id="checkmode"
                      className="hidden"
                    />
                    <label
                      htmlFor="checkmode"
                      className="cursor-pointer"
                      onClick={handleTheme}
                    >
                      <div className="switch-container relative w-8 h-2 flex items-center bg-gray-400  dark:bg-slate-700 rounded-full p-2">
                        <div className="switch-ball absolute left-0 w-3 h-3 bg-white rounded-full shadow-2xl transition-transform  translate-x-1 "></div>
                      </div>
                    </label>
                  </div>



                   
                

                </div>
              </div>
            </div>


                  {/* Notification */}
            <GiHamburgerMenu onClick={()=> setOpen(!open)} className="fixed right-2 top-2.5 h-5 w-5 text-gray-600 dark:text-gray-100"/>
            <IoNotificationsOutline className="fixed right-[5.6rem] top-2 h-6 w-6 text-gray-600 dark:text-gray-100"/>
        
            {/* <div
              id="notif-content"
              className="  notification-content   px-5 absolute top-10 right-2 z-10 -mr-10 sm:mr-0 overflow-scroll hidden shadow-2xl bg-white  text-slate-700 dark:bg-slate-900 dark:text-slate-100 rounded-2xl "
            >
              <div className=" max-h-96  dark:bg-dark-6">
                <div className=" text-lg text-left py-3">Notifications</div>
                <div className="cursor-pointer relative flex items-start py-2 border-b border-gray-100 zoom-in">
                  <div className="overflow-hidden">
                    <div className="flex items-start">
                      <Link
                      
                      className="font-medium mr-5">
                        Unexpected Vibration Occured
                      </Link>
                      <div className="text-xs text-gray-400 ml-auto whitespace-no-wrap">
                        {" "}
                        06:37 am{" "}
                      </div>
                    </div>
                    <div className="w-full text-gray-400">
                      {" "}
                      in Genie Z4525JRT{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            {/* Profile */}
            <div className="inline">
              <FaUser className=" border border-gray-700 dark:border-white fixed right-12 top-2 rounded-full pt-1 h-6 w-6"
                onClick={() => setOpenUserLogo((prev) => !prev)}
              />
            </div>
            {openUserLogo && (
              <div
                id="dropdown-content"
                className="dropdown-box  text-xs absolute w-fit top-12 right-12 z-20 "
              >
                <div
                  id="profile-dropdown"
                  className="bg-white dark:bg-black rounded-lg box  shadow-xl"
                >
                  <div className="px-3 py-2 border-theme-40 dark:border-dark-3 ">
                    
                    <div className="text-xs  dark:text-gray-300 hover:text-white   hover:bg-slate-900 dark:hover:bg-slate-600 rounded p-1 items-center flex">
                    <FaUser className="border border-gray-800 hover:border-white dark:border-white dark:hover:border-white rounded-full pt-1 h-5 w-5"
                        onClick={() => setOpenUserLogo((prev) => !prev)}
                      />    
                      <div className="mx-2 ">

                       {user}
                      </div>
                       
                   </div>
                  </div>
                  <div className="px-2 text-xs">
                    <Link
                      // href=""
                      className="flex items-center p-2 transition duration-300 ease-in-out  hover:bg-black hover:text-white rounded-md"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="w-4 h-4 mr-2 feather feather-user"
                      >
                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                        <circle cx="12" cy="7" r="4"></circle>
                      </svg>{" "}
                      Position{" "}
                    </Link>
                    <Link
                      // href="#"
                      className="flex items-center p-2 transition duration-300 ease-in-out hover:bg-black hover:text-white  rounded-md"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="w-4 h-4 mr-2 feather feather-help-circle"
                      >
                        <circle cx="12" cy="12" r="10"></circle>
                        <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                        <line x1="12" y1="17" x2="12.01" y2="17"></line>
                      </svg>{" "}
                      Help{" "}
                    </Link>
                  </div>


                  {perms.indexOf("ADMIN.ALL") !== -1 && (
                    <div className="p-2  border-theme-40 dark:border-dark-3  hover:bg-black hover:text-white rounded-md text-center items-center ">
                      <a href="/settings">
                        <span className="mr-[80%] px-3">Settings</span>
                      </a>
                    </div>
                  )}

                  <div
                    className="p-2 border-t border-theme-40 dark:border-dark-3 hover:bg-black hover:text-white  dark:hover:bg-slate-700 "
                    onClick={handleLogut}
                  >
                    <span className="cursor-pointer flex items-center p-1 transition duration-300 ease-in-out  dark:hover:bg-dark-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="w-4 h-4 mr-2 feather feather-log-out"
                      >
                        <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                        <polyline points="16 17 21 12 16 7"></polyline>
                        <line x1="21" y1="12" x2="9" y2="12"></line>
                      </svg>{" "}
                      Exit
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;


