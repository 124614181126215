import React from 'react'
import { capitalizeFirstLetter, currency, formatDate } from '../../AssetDetails/Tabs/FormatDate'

const LeaseDashboard = ({leaseSpecificDetails}) => {
  return (
    <>
         {/* <div id="opD-dashboard" className="leaseD-tabs block mt-4">
            <div className="grid grid-cols-12 gap-6">
              <div className="intro-y box col-span-12 lg:col-span-4 zoom-in bg-white dark:bg-slate-700 rounded-lg">
                <div className="flex items-center p-5 border-b border-gray-200 dark:border-dark-5">
                  <h2 className="font-medium text-base mr-auto dark:text-slate-300">
                    Custom Feilds
                  </h2>
                </div>
                <div className="p-5 text-sm">
                  <div className="flex flex-col sm:flex-row">
                    <div className="mr-auto">
                      <div className="font-medium text-gray-500 dark:text-slate-300">
                        Customer PO No
                      </div>
                    </div>
                    <div className="flex">
                      <div className="text-center">
                        <div className="bg-lime-500 text-white text-xs rounded px-2 mt-1 dark:text-slate-300">
                          {leaseSpecificDetails?.customer_po_no}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col sm:flex-row mt-4">
                    <div className="mr-auto">
                      <div className="font-medium text-gray-500 dark:text-slate-300">Customer</div>
                    </div>
                    <div className="flex">
                      <div className="text-center">
                        <div className="font-medium">
                          {leaseSpecificDetails?.customer}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col sm:flex-row mt-4">
                    <div className="mr-auto">
                      <div className="font-medium text-gray-500 dark:text-slate-300">Cuerrency</div>
                    </div>
                    <div className="flex">
                      <div className="text-center">
                        <div className="font-medium">
                          {leaseSpecificDetails?.currency}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col sm:flex-row mt-4">
                    <div className="mr-auto">
                      <div className="font-medium text-gray-500 dark:text-slate-300"> Status </div>
                    </div>
                    <div className="flex">
                      <div className="text-center">
                        <div className="bg-orange-500 text-white text-xs rounded px-2 mt-1">
                          {leaseSpecificDetails?.lease_status}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="intro-y box col-span-12 lg:col-span-4 zoom-in bg-white dark:bg-slate-700 rounded-lg">
                <div className="flex items-center p-5 border-b border-gray-200 dark:border-dark-5">
                  <h2 className="font-medium text-base mr-auto py-3"></h2>
                </div>
                <div className="p-5 text-sm">
                  <div className="flex flex-col sm:flex-row">
                    <div className="mr-auto">
                      <div className="font-medium text-gray-500 dark:text-slate-300">
                        Contract Value
                      </div>
                    </div>
                    <div className="flex">
                      <div className="text-center">
                        <div className="font-medium">
                          {leaseSpecificDetails?.contract_value}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col sm:flex-row mt-4">
                    <div className="mr-auto">
                      <div className="font-medium text-gray-500 dark:text-slate-300">
                        Transportation Charges
                      </div>
                    </div>
                    <div className="flex">
                      <div className="text-center">
                        <div className="font-medium">
                          {" "}
                          {leaseSpecificDetails?.transportation_charge}{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col sm:flex-row mt-4">
                    <div className="mr-auto">
                      <div className="font-medium text-gray-500 dark:text-slate-300">
                        Normal Amount
                      </div>
                    </div>
                    <div className="flex">
                      <div className="text-center">
                        <div className=" font-medium">
                          {leaseSpecificDetails?.normal_amount}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col sm:flex-row mt-4">
                    <div className="mr-auto">
                      <div className="font-medium text-gray-500 dark:text-slate-300">
                        Overtime Amount
                      </div>
                    </div>
                    <div className="flex">
                      <div className="text-center">
                        <div className=" font-medium">
                          {leaseSpecificDetails?.overtime_amount}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="intro-y box col-span-12 lg:col-span-4 zoom-in bg-white dark:bg-slate-700 rounded-lg">
                <div className="flex items-center p-5 border-b border-gray-200 dark:border-dark-5">
                  <h2 className="font-medium text-base mr-auto py-3"></h2>
                </div>
                <div className="p-5 text-sm">
                  <div className="flex flex-col sm:flex-row">
                    <div className="mr-auto">
                      <div className="font-medium text-gray-500 dark:text-slate-300">
                        Reimbursements
                      </div>
                    </div>
                    <div className="flex">
                      <div className="text-center">
                        <div className="bg-lime-500 text-white text-xs rounded px-2 mt-1">
                          {leaseSpecificDetails?.reimbursements}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col sm:flex-row mt-4">
                    <div className="mr-auto">
                      <div className="font-medium text-gray-500 dark:text-slate-300">
                        Total Claimable Amount
                      </div>
                    </div>
                    <div className="flex">
                      <div className="text-center">
                        <div className="font-medium">
                          {leaseSpecificDetails?.total_claimable_amount}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col sm:flex-row mt-4">
                    <div className="mr-auto">
                      <div className="font-medium text-gray-500 dark:text-slate-300">
                        Rental Start Date
                      </div>
                    </div>
                    <div className="flex">
                      <div className="text-center">
                        <div className="bg-lime-500 text-white text-xs rounded px-2 mt-1">
                          {leaseSpecificDetails?.rental_start_date}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col sm:flex-row mt-4">
                    <div className="mr-auto">
                      <div className="font-medium text-gray-500 dark:text-slate-300">
                        {" "}
                        Rental End Date{" "}
                      </div>
                    </div>
                    <div className="flex">
                      <div className="text-center">
                        <div className="bg-red-500 text-white text-xs rounded px-2 mt-1">
                          {leaseSpecificDetails?.rental_end_date}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

           
            </div>
          </div> */}

<div className="flex flex-col  md:flex-row text-xs w-[calc(200vh-10%)] rounded-2xl border
   justify-between px-8 py-4 mt-2 dark:bg-slate-900 bg-white space-x-2">
        {/* Frame 1 */}
        <div className="flex-1 border px-10 py-4 rounded-2xl space-y-4 ">
          <div className="p-2 flex justify-between border rounded-lg h-8">
            <span className="text-gray-500">Customer PO No</span>
            <span className="block font-semibold text-xs">
                {leaseSpecificDetails?.customer_po_no === "null" ? '--': leaseSpecificDetails?.customer_po_no}
            </span>
          </div>
          <div className="p-2 flex justify-between border rounded-lg h-8">
            <span className="text-gray-500">Customer</span>
            <span className="block font-semibold text-xs">
                {leaseSpecificDetails?.customer}
            </span>
          </div>
          <div className="p-2 flex justify-between border rounded-lg h-8">
            <span className="text-gray-500">Cuerrency</span>
            <span className="block font-semibold text-xs">
            {leaseSpecificDetails?.currency}
            </span>
          </div>
               
          <div className="p-2 flex justify-between border rounded-lg h-8">
            <span className="text-gray-500">Status</span>
            <span className={`block font-semibold text-xs
                 ${leaseSpecificDetails?.lease_status === 'inactive'? 'text-gray-500': 
                    leaseSpecificDetails?.lease_status === "never assigned" ? "text-red-600"  : "text-green-500"}`}>
            {capitalizeFirstLetter(leaseSpecificDetails?.lease_status)}
            </span>
          </div>
           <div className="p-2 flex justify-between border rounded-lg h-8">
            <span className="text-gray-500">Rental Start Date</span>
            <span className="block font-semibold text-xs">
            {formatDate(leaseSpecificDetails?.rental_start_date)}
            </span>
          </div>
          <div className="p-2 flex justify-between border rounded-lg h-8">
            <span className="text-gray-500"> Rental End Date</span>
            <span
              className={`block font-semibold text-xs`}
            >
              {formatDate(leaseSpecificDetails?.rental_end_date)}
              
            </span>
          </div>

          <div className="p-2 flex justify-between border rounded-lg h-8">
            <span className="text-gray-500">Rental Extended End Date</span>
            <span className="block font-semibold text-xs">
              {leaseSpecificDetails?.rental_extended_date ? 
                <>
            {formatDate(leaseSpecificDetails?.rental_extended_date)}
                </> 
                : "--"
                }
            </span>
          </div>
        </div>

        

       
        <div className="flex-1 border px-10 py-4 rounded-2xl space-y-4">
          <div className="p-2 flex justify-between border rounded-lg h-8">
            <span className="text-gray-500">Contract Value</span>
            <span className="block font-semibold text-xs">
            ₹{currency(leaseSpecificDetails?.contract_value)}
            </span>
          </div>
          <div className="p-2 flex justify-between border rounded-lg h-8">
            <span className="text-gray-500">Transportation Charges</span>
            <span className="block font-semibold text-xs">
            ₹{currency(leaseSpecificDetails?.transportation_charge)}
            </span>
          </div>
          <div className="p-2 flex justify-between border rounded-lg h-8">
            <span className="text-gray-500">Normal Amount</span>
            <span className="block font-semibold text-xs">
            ₹{(currency(leaseSpecificDetails?.normal_amount))}
            </span>
          </div>
          <div className="p-2 flex justify-between border rounded-lg h-8">
            <span className="text-gray-500"> Overtime Amount</span>
            <span className="block font-semibold text-xs">
            ₹{currency(leaseSpecificDetails?.overtime_amount)}
            </span>
          </div>
          <div className="p-2 flex justify-between border rounded-lg h-8">
            <span className="text-gray-500">  Reimbursements</span>
            <span className="block font-semibold text-xs">
            ₹{currency(leaseSpecificDetails?.reimbursements)}
            </span>
          </div>
          <div className="p-2 flex justify-between border rounded-lg h-8">
            <span className="text-gray-500">  Total Claimable Amount</span>
            <span className="block font-semibold text-xs">
            ₹{currency(leaseSpecificDetails?.total_claimable_amount)}
            </span>
          </div>
        </div>

       


   
     
      </div>
    </>
  )
}

export default LeaseDashboard
