import React from "react";
import { capitalizeFirstLetter } from "./FormatDate";
import PhotoData from "./PhotoData";

const CommercialDetails = ({ assetDetails, imageUrl, NoImage }) => {
  return (
  
    // <>
    //   <div className="flex flex-col  text-xs w-full border
    //    rounded-3xl justify-between px-8 py-2 mt-2 dark:bg-slate-900 h-[400px]
       
    //    bg-white space-y-2 md:space-y-0">
    //     <div className="flex">

    //     </div>
        //  <PhotoData
        //      imageUrl={imageUrl} 
        //      NoImage={NoImage} 
          
        //      />
    //     {/* Frame 1 */}
    //     <div className="flex flex-col justify-between">
          // <div className="py-2 hover:bg-gray-100 px-1 rounded-lg">
          //   <span className="text-gray-500">Machine No. - Model No.</span>
          //   <span className="block font-semibold text-xs">
          //     {assetDetails?.asset_no} - {assetDetails?.model}
          //   </span>
          // </div>
          // {assetDetails?.device_no  && <>
          // <div className="py-2 text-end hover:bg-gray-100 px-1 rounded-lg">
          //   <span className="text-gray-500">Device No.</span>
          //   <span className="block font-semibold text-xs">
          //     {assetDetails?.device_no}
          //   </span>
          // </div>
          // <div className="py-2 hover:bg-gray-100 px-1 rounded-lg">
          //   <span className="text-gray-500">Serial No.</span>
          //   <span className="block font-semibold text-xs">
          //     {assetDetails?.serial_no}
          //   </span>
          // </div>

    //       </>}
    //     <div className="py-2 text-end hover:bg-gray-100 px-1 rounded-lg">
    //         <span className="text-gray-500">Category</span>
    //         <span className="block font-semibold text-xs">
    //           {assetDetails?.category}
    //         </span>
    //       </div>
    //     </div>

    //     {/* Frame 2 */}
    //     <div className="flex justify-between">
          
          
    //     </div>

    //     {/* Frame 3 */}
    //     <div className="flex justify-between">
    //       <div className="py-2 hover:bg-gray-100 px-1 rounded-lg">
    //         <span className="text-gray-500">Used Asset or New</span>
    //         <span className="block font-semibold text-xs">
    //           {assetDetails?.config_data?.ansi_or_new}
    //         </span>
    //       </div>
    //       <div className="py-2 text-end hover:bg-gray-100 px-1 rounded-lg">
    //         <span className="text-gray-500">Battery Type</span>
    //         <span className="block font-semibold text-xs">
    //           {assetDetails?.config_data?.battery_type}
    //         </span>
    //       </div>
    //     </div>

    //     {/* Frame 4 */}
    //     <div className="flex justify-between">
    //       <div className="py-2 hover:bg-gray-100 px-1 rounded-lg">
    //         <span className="text-gray-500">Location</span>
    //         <span className="block font-semibold text-xs">
    //           {assetDetails?.site_location}
    //         </span>
    //       </div>
    //       <div className="py-2 text-end hover:bg-gray-100 px-1 rounded-lg">
    //         <span className="text-gray-500">Year of Manufacturing</span>
    //         <span className="block font-semibold text-xs">
    //           {assetDetails?.yom}
    //         </span>
    //       </div>
    //     </div>

    //     {/* Frame 5 */}
    //     <div className="flex justify-between">
    //       <div className="py-2 hover:bg-gray-100 px-1 rounded-lg">
    //         <span className="text-gray-500">2wd</span>
    //         <span className="block font-semibold text-xs">
    //           {assetDetails?.config_data?.two_or_four_wd}
    //         </span>
    //       </div>
    //       <div className="py-2 text-end hover:bg-gray-100 px-1 rounded-lg">
    //         <span className="text-gray-500">Make</span>
    //         <span className="block font-semibold text-xs">
    //           {assetDetails?.make}
    //         </span>
    //       </div>
    //     </div>

    //     {/* Frame 6 */}
    //     <div className="flex justify-between">
    //       <div className="py-2  hover:bg-gray-100 px-1 rounded-lg">
    //         <span className="text-gray-500">Purchased From</span>
    //         <span className="block font-semibold text-xs">
    //           {assetDetails?.purchased_from}
    //         </span>
    //       </div>
    //       <div className="py-2 text-end hover:bg-gray-100 px-1 rounded-lg">
    //         <span className="text-gray-500">Tracker</span>
    //         <span
              // className={`block font-semibold text-xs ${
              //   assetDetails?.device_id ? "text-blue-600" : "text-gray-500"
              // }`}
    //         >
    //           {assetDetails?.device_id ? "Installed" : "Not Installed"}
    //         </span>
    //       </div>
    //     </div>

    //     {/* Frame 7 */}
    //     <div className="flex justify-between ">
    //       <div className="py-2 hover:bg-gray-100 px-1 rounded-lg">
    //         <span className="text-gray-500">Capacity</span>
    //         <span className="block font-semibold text-xs">
    //           {assetDetails?.capacity}kg-{assetDetails?.hieght_mechine}ft
    //         </span>
    //       </div>
    //       <div className="py-2 text-end hover:bg-gray-100 px-1 rounded-lg">
    //         <span className="text-gray-500">Status</span>
    //         <span
              // className={`block font-semibold text-xs ${
              //   assetDetails?.lease_status === "inactive"
              //     ? "text-slate-600"
              //     : assetDetails?.lease_status === 'active' ?  "text-green-500" :
              //     assetDetails?.lease_status === 'expired' ? 'text-red-600': 'text-blue-600' 
              // }`}
    //         >
    //           {capitalizeFirstLetter(assetDetails?.lease_status)}
    //         </span>
    //       </div>
    //     </div>
    //       {/* Frame 8 */}
        
    //   </div>
    // </>
    <>
    <div className="border rounded-3xl w-[400px] pt-3 mb-16 pb-4">
    <div className="flex flex-row-reverse justify-between px-4 gap-6 text-left">

          <PhotoData
             imageUrl={imageUrl} 
             NoImage={NoImage} 
             
             />
           <div className="flex flex-col text-left">
            <div className=" hover:bg-gray-100 px-1 rounded-lg">
              <span className="text-gray-500 text-xs">Asset No.</span>
              <span className="block font-bold text-sm ">
                {assetDetails?.asset_no}
              </span>
            </div>
            <div className=" hover:bg-gray-100 px-1 rounded-lg">
              <span className="text-gray-500 text-xs">Model</span>
              <span className="block  text-xs font-bold ">
              {assetDetails?.model}
              </span>
            </div>
            <div className=" hover:bg-gray-100 px-1 rounded-lg">
              <span className="text-gray-500 text-xs">Serial No</span>
              <span className="block  text-xs font-bold ">
              {assetDetails?.serial_no}
              </span>
            </div>
            <div className=" hover:bg-gray-100 px-1 rounded-lg">
              <span className="text-gray-500 text-xs">Category</span>
              <span className="block  text-xs font-bold ">
              {assetDetails?.category}
              </span>
            </div>
           </div>
             </div>
      <div className="px-4  space-y- mt-4 gap-3">
      {assetDetails?.device_no  && <>
          <div className="p-2 borde px-2 flex justify-between hover:bg-gray-100  rounded-lg">
            <span className="text-gray-500 text-xs ">Tracking Device No.</span>
            <span className="block font-semibold text-xs">
              {assetDetails?.device_no}
            </span>
          </div>
      </>}

            <div className="py-2 hover:bg-gray-100 px-2 rounded-lg  borde  flex justify-between">
            <span className="text-gray-500 text-xs">Serial No.</span>
            <span className="block font-semibold text-xs text-left">
              {assetDetails?.serial_no}
            </span>
          </div>

          <div className="py-2 hover:bg-gray-100 px-2 rounded-lg text-left borde  flex justify-between">
            <span className="text-gray-500 text-xs">Used Asset or New</span>
            <span className="block font-semibold text-xs">
             {assetDetails?.config_data?.ansi_or_new}
            </span>
          </div>

          <div className="py-2 hover:bg-gray-100 px-2 rounded-lg  borde  flex justify-between">
            <span className="text-gray-500 text-xs">Battery Type</span>
            <span className="block font-semibold text-xs text-left">
            {assetDetails?.config_data?.battery_type}
            </span>
          </div>

          <div className="py-2 hover:bg-gray-100 px-2 rounded-lg  borde  flex justify-between">
            <span className="text-gray-500 text-xs">Site Location</span>
            <span className="block font-semibold text-xs">
            {assetDetails?.site_location}
            </span>
          </div>

          <div className="py-2 hover:bg-gray-100 px-2 rounded-lg  borde  flex justify-between">
            <span className="text-gray-500 text-xs">Year of Manufacturing</span>
            <span className="block font-semibold text-xs">
            {assetDetails?.yom}
            </span>
          </div>

          <div className="py-2 hover:bg-gray-100 px-2 rounded-lg  borde  flex justify-between">
            <span className="text-gray-500 text-xs">2wd</span>
            <span className="block font-semibold text-xs">
            {assetDetails?.config_data?.two_or_four_wd}
            </span>
          </div>


          <div className="py-2 hover:bg-gray-100 px-2 rounded-lg  borde  flex justify-between">
            <span className="text-gray-500 text-xs">Make</span>
            <span className="block font-semibold text-xs">
            {assetDetails?.make}
            </span>
          </div>

          <div className="py-2 hover:bg-gray-100 px-2 rounded-lg  borde  flex justify-between">
            <span className="text-gray-500 text-xs">Purchased From</span>
            <span className="block font-semibold text-xs">
            {assetDetails?.purchased_from}
            </span>
          </div>   

          <div className="py-2 hover:bg-gray-100 px-2 rounded-lg  borde  flex justify-between">
            <span className="text-gray-500 text-xs">Tracker</span>
            <span className={`block font-semibold text-xs
              ${
                assetDetails?.device_id ? "text-blue-600" : "text-gray-500"
              }
              `}>
            {assetDetails?.device_id ? "Installed" : "Not Installed"}
            </span>
          </div>        


          <div className="py-2 hover:bg-gray-100 px-2 rounded-lg  borde  flex justify-between">
            <span className="text-gray-500 text-xs">Capacity</span>
            <span className="block font-semibold text-xs">
            {assetDetails?.capacity}kg-{assetDetails?.hieght_mechine}ft
            </span>
          </div>  
          <div className="py-2 hover:bg-gray-100 px-2 rounded-lg  borde  flex justify-between">
            <span className="text-gray-500 text-xs">Machine Sold</span>
            <span className="block font-semibold text-xs">
            {assetDetails?.is_sold === null ? "No": "Yes"}
            </span>
          </div>  


          <div className="py-2 hover:bg-gray-100 px-2 rounded-lg  borde  flex justify-between">
            <span className="text-gray-500 text-xs">Status</span>
            <span className={`block font-semibold text-xs 
              ${
                assetDetails?.lease_status === "inactive"
                  ? "text-slate-600"
                  : assetDetails?.lease_status === 'active' ?  "text-green-500" :
                  assetDetails?.lease_status === 'expired' ? 'text-red-600': 'text-blue-600' 
              }
              `}>
               {capitalizeFirstLetter(assetDetails?.lease_status)}
            </span>
          </div>  

      </div>
    </div>
    </>
  );
};

export default CommercialDetails;
