import React, { useEffect, useState } from "react";
import MISReportBtn from "../../Buttons/MISReportBtn";
import ExcelReportBtn from "../../Buttons/ExcelReportBtn";
import { useNavigate } from "react-router-dom";
import NewAssetBtn from "../../Buttons/NewAssetBtn";
import { setBaseUrl } from "../../../config";
import axios from "axios";
import OperatorSearch from "../../Search/OperatorSearch";

function UpperTab({  getAllOperators,  }) {
  // const [filter, setFilter] = useState("all");
  //setting state for search suggestions and page count for pagination
  const [searchSuggetions, setSearchSuggetions] = useState({});
  const [searchValue, setSearchValue] = useState('');
  const [searchTypeArray, setSearchTypeArray] = useState([]);
  const [showSearchDiv, setShowSearchDiv] = useState([]);
  const [searchType, setSearchType] = useState("");
  
  const handleSearchCategory = (value) => {
    // console.log(value);
    switch (value) {
      // case "all":
       
      //   setShowSearchDiv([]);
      //   setSearchTypeArray([]);
      //   setSearchValue("")
      //   getAllOperators();
      //   break;
      case "adhaarNo":
        setSearchType("aadhar-no");
        let adNos = Object.keys(searchSuggetions.aadhar);
        setSearchTypeArray(adNos);
        break;
      case "pf":
        setSearchType("pf-account");
        let pfAcNo = Object.keys(searchSuggetions.pf_account_no);
        setSearchTypeArray(pfAcNo);
        break;
      case "name":
        setSearchType("name");
        let opName = Object.keys(searchSuggetions.name);
        setSearchTypeArray(opName);
      default:
        break;
    }
  };
  const getSearchData = async () => {
    try {
      const { data } = await axios.get(`${setBaseUrl}/operator/search`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
        },
      });
      // console.log(data);
      setSearchSuggetions(data);
      // setItems(data.asset_data.length());
    } catch (error) {
      // console.error(error);
    }
  };

  useEffect(() => {
    getSearchData();
  }, [searchSuggetions]);

  function search(query) {
    query = query.trim();
    if (query == "") {
      setShowSearchDiv([]);
      return;
    }
    const filteredData = searchTypeArray.filter((item) =>
      item.toLowerCase().includes(query.toLowerCase())
    );
    if (filteredData.length > 0) {
      setShowSearchDiv(filteredData);
    } else {
      setShowSearchDiv(["no results"]);
    }

    // console.log(filteredData);
  }
  const handleSearch = (e) => {
    search(e.target.value);
    setSearchValue(e.target.value);
  };
  const handleSearchClicik = (item) => {
    // console.log(searchType, item);
    getAllOperators(searchType, item);
    setShowSearchDiv([]);
  };
  return (
    <div class="flex justify-between flex-col md:flex-row items-center border-b pb-2 py-0">
      <p class="inline font-bold text-xl  text-slate-700 dark:text-slate-200">
        Operator
      </p>





      <div className="flex flex-col items-center md:flex-row">
        <div className="flex flex-col md:flex-row"/>
        <div className="">
        <OperatorSearch handleSearchCategory={handleSearchCategory} searchValue={searchValue} handleSearch={handleSearch} handleSearchClicik={handleSearchClicik}/>

          {showSearchDiv.length > 0 && (
            <div className="absolute bg-white p-2 border-2 border-[#b3a7a7] rounded-[9px] w-full h-[200px] z-[9999] mt-10 overflow-y-scroll">
              {showSearchDiv.map((item) => (
                <p
                  onClick={() => handleSearchClicik(item)}
                  className="cursor-pointer hover:bg-slate-400 rounded-md p-2"
                >
                  {item}
                </p>
              ))}
            </div>
          )}
        </div>

      </div>
    </div>
  );
}

export default UpperTab;
