import React from 'react'
import { capitalizeFirstLetter, formatDate } from '../../AssetDetails/Tabs/FormatDate'
import AssetDetailsTab from '../AssetDetailsTab'

const LeaseInfo = ({leaseSpecificDetails, asset_id, leaseId}) => {
  return (
    <>
      
      
          <div className="flex flex-col md:flex-row 
            text-xs w-full border rounded-2xl justify-between px-8 py-4 mt-2 dark:bg-slate-900 bg-white space-y-2 md:space-y-0">
        {/* Frame 1 */}
        <div className="flex-1">
          <div className="py-2">
            <span className="text-gray-500">RSO Id</span>
            <span className={`block font-semibold text-xs 
               `}>
            {capitalizeFirstLetter(leaseSpecificDetails?.odoo_order_id)}
            </span>
          </div>
          <div className="py-2">
            <span className="text-gray-500">Asset No</span>
            <span className={`block font-semibold text-xs 
               `}>
                {/* AvijitDa */}
            {/* {leaseSpecificDetails?.asset_no} */}
            <AssetDetailsTab asset_id={asset_id} leaseId={leaseId}  />
            </span>
          </div>
      
        </div>


        <div className="flex-1">
          <div className="py-2">
            <span className="text-gray-500">Status</span>
            <span className={`block font-semibold text-xs 
                ${leaseSpecificDetails?.lease_status === 'inactive'? 'text-gray-500': 
                    leaseSpecificDetails?.lease_status === "never assigned" ? "text-red-600"  : "text-green-500"}`}>
            {capitalizeFirstLetter(leaseSpecificDetails?.lease_status)}
            </span>
            
          </div>
          <div className="py-2">
            <span className="text-gray-500">Lease Type</span>
            <span className={`block font-semibold text-xs 
               `}>
                 {/* AvijitDa */}
            {leaseSpecificDetails?.lease_type}
            </span>
      </div>
        </div>

        {/* Frame 2 */}
        <div className="flex-1">
          <div className="py-2">
            <span className="text-gray-500">Serial No.</span>
            <span className="block font-semibold text-xs">
            {formatDate(leaseSpecificDetails?.rental_start_date)}
            </span>
          </div>
          <div className="py-2">
            <span className="text-gray-500">Customer Name</span>
            <span className={`block font-semibold text-xs 
               `}>
                 {/* AvijitDa */}
            {leaseSpecificDetails?.customer}
            </span>
      </div>
          
        </div>

        {/* Frame 3 */}
        <div className="flex-1">
          <div className="py-2">
            <span className="text-gray-500"> Rental End Date:</span>
            <span className="block font-semibold text-xs">
            {formatDate(leaseSpecificDetails?.rental_end_date)}
            </span>
          </div>
          <div className="py-2">
            <span className="text-gray-500">Runtime Duration (Till last update)</span>
            <span className={`block font-semibold text-xs 
               `}>
                 {/* AvijitDa */}
            {leaseSpecificDetails?.runtime_duration}
            </span>
      </div>
        </div>

  
      </div>
    </>
  )
}

export default LeaseInfo
