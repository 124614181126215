import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { setBaseUrl } from "../../config";
import Loader from "../Loader";
import { LoaderContext } from "../../Context/LoaderContext";
import { PermissionContext } from "../../Context/PermissionsContext";
import { useNavigate, useParams } from "react-router-dom";
import ifscIco from "../../components/OperatorDetails/assets/ifscIco.svg";
import odooIco from "../../components/OperatorDetails/assets/adhaarIcon.svg";
import adhaarIco from "../../components/OperatorDetails/assets/adhaarIcon2.svg";
import joiningIco from "../../components/OperatorDetails/assets/joiningIcon.svg";
import moneyIco from "../../components/OperatorDetails/assets/moneyIco.svg";
import phoneIco from "../../components/OperatorDetails/assets/phoneIcon.svg";
import profileIco from "../../components/OperatorDetails/assets/profileIco.svg";
import bankAcIco from "../../components/OperatorDetails/assets/bankAcIco.svg";
import pfAcIco from "../../components/OperatorDetails/assets/pfIco.svg";
import CardForDetails from "./CardForDetails";
import { currency, formatAadhaar, formatDate } from "../Modals/AssetDetails/Tabs/FormatDate";

function OperatorDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [perms, setPerms] = useContext(PermissionContext);
  const [operatorDetail, setOperatorDetail] = useState({});
  const loader = useContext(LoaderContext)[0];
  const setLoader = useContext(LoaderContext)[1];
  const [reload, setReload] = useState(false);
  const [history, setHistory] = useState(false);
  const getOperatorDetail = async () => {
    try {
      setLoader(true);
      const { data } = await axios.get(`${setBaseUrl}/operator/get_by_id`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
          id: id,
        },
      });
      // console.log(data);
      setOperatorDetail(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getOperatorDetail();
  }, [reload]);


  // console.log(formatDate(operatorDetail?.date_list[0]?.joining_date));

  if (loader) {
    return <Loader />;
  } else {
    return (
      <div id="operator-details" className="">
        <div className="flex border  fixed bottom-0 left-0 right-0 items-center h-12">
          <button
          className="ml-16 text-xs rounded-full border  w-[67px] h-7 "
          onClick={() => navigate("/operators")}>
            back
          </button>
         
          <div class="sm:flex items-center ml-auto mt-0 gap-6 hidden text-sm">
            {(perms.indexOf("OPERATOR.ALL") !== -1 ||
              perms.indexOf("OPERATOR.CRU") !== -1 ||
              perms.indexOf("ADMIN.ALL") !== -1) && (
              <button
                onClick={() => navigate(`/operators/updateOperator/${id}`)}
                class="text-xs text-white bg-black rounded-full w-[67px] h-7 "
              >Update
               
              </button>
            )}
            <button
              class="border rounded-full text-xs w-[67px] h-7 mr-2"
              onClick={() => setReload((prev) => !prev)}
            >
              Reload
            </button>
          </div>
        </div>

        <div className="flex justify-evenly space-y-4 p-4 bg-white dark:bg-slate-700 border rounded-2xl text-xs">
          {/* User Details Card */}
          <div className="flex flex-col space-y-3 ">
            <h1 className="font-bold ">Operator's Informations</h1>
            <div className="flex items-center space-x-3">
              <div className="flex items-center space-x-2">
                {/* <img src={profileIco} alt="Aadhaar Icon" className="h-4 w-4" /> */}
                <span className="dark:text-white text-gray-500">
                  Name :
                </span>
                
                <span className="font-bold text-base">{operatorDetail?.name}</span>
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <div className="flex items-center space-x-2">
                {/* <img src={phoneIco} alt="Phone Icon" className="h-4 w-4" /> */}
                <span className="dark:text-white text-gray-500">
                  Mobile No.:
                </span>
                <span className="">
                  {operatorDetail?.phone?.types}-
                  {operatorDetail?.phone?.phone_no}
                </span>
              </div>
            </div>
            <div className="flex items-center space-x-2">
              {/* <img src={adhaarIco} alt="Aadhaar Icon" className="h-4 w-4" /> */}
              <span className="dark:text-white text-gray-500">
                Aadhaar No.:
              </span>
              <span className="">{operatorDetail?.aadhar_no}</span>
            </div>
            <div className="flex items-center space-x-2">
              {/* <img src={odooIco} alt="Odoo Employee Icon" className="h-4 w-4" /> */}
              <span className="dark:text-white text-gray-500">
                Odoo Employee No.:
              </span>
              <span className="">
                {operatorDetail?.odoo_employee_no}
              </span>
            </div>
          </div>

          {/* <hr className="h-[100px] border " /> */}

          {/* Employment Details Card */}
          <div className="flex flex-col space-y-3">
         
            <div className="flex flex-col space-y-2">
              <div className="flex items-center space-x-2">
                {/* <img src={pfAcIco} alt="PF Account Icon" className="h-4 w-4" /> */}
                <span className="dark:text-white text-gray-500">
                  PF Account No.:
                </span>
                <span className="font-bold">
                  {parseInt(operatorDetail?.pf_account_no)}
                </span>
              </div>
              <div className="flex items-center space-x-2">
                {/* <img
                  src={bankAcIco}
                  alt="Bank Account Icon"
                  className="h-4 w-4"
                /> */}
                <span className="dark:text-white text-gray-500">
                  Bank Account No.:
                </span>
                <span className="font">
                  {operatorDetail?.bank_details?.account_no}
                </span>
              </div>
              <div className="flex items-center space-x-2">
                {/* <img src={ifscIco} alt="IFSC Icon" className="h-4 w-4" /> */}
                <span className="dark:text-white text-gray-500">
                  IFSC Code:
                </span>
                <span className="font-b">
                  {operatorDetail?.bank_details?.ifsc_code}
                </span>
              </div>
              <div className="flex items-center space-x-2">
                {/* <img src={moneyIco} alt="Salary Icon" className="h-4 w-4" /> */}
                <span className="dark:text-white text-gray-500">
                  Net Inhand Salary:
                </span>
                <span className="font-bol">
                  ₹{currency(operatorDetail?.net_inhand_salary || "--")}
                </span>
              </div>
            </div>
          </div>

          {/* <hr className="h-[100px] border " /> */}
          {/* Date of Joining */}
          {operatorDetail?.date_list?.map((date) => (<>
         
        <div className="flex flex-col space-y-3 " key={date}>
          
          <div className="flex items-center space-x-2">
            {/* <img
              src={joiningIco}
              alt="Joining Date Icon"
              className="h-4 w-4"
            /> */}
            <span className="dark:text-white text-gray-500">
              Date of Joining:
            </span>
            <span className="font-bol">
          {date?.joining_date ? <>
              {formatDate(date?.joining_date)}
          </> : '--'}
            
            </span>
          </div>
          <div className="flex items-center space-x-2">
           
            <span className="dark:text-white text-gray-500">
              Date of Leaving:
            </span>
            <span className="">
            {date?.leaving_date ? <>
              {formatDate(date?.leaving_date)}
            </> : "--"}
            </span>
          </div>
          
          <button
            className="px-2 py-1 bg-black text-white dark:bg-white dark:text-black cursor-pointer rounded"
            onClick={() => setHistory(!history)}
          >
            Show More
          </button>
          </div>
          {history && (
            <>
              <div className="absolute right-24 text-xs top-[80%] h-[300px] overflow-y-auto bg-white dark:bg-slate-900 shadow-xl rounded-xl overflow-hidden p-4">
                <button
                  className="absolute right-4"
                  onClick={() => setHistory(false)}
                >
                  Close
                </button>
                <div className=" space-y-2 p-2 mt-6">
                  <div className="flex items-center space-x-2 ">
                    
                    <span className="dark:text-white text-gray-500">
                      Date of Joining:
                    </span>
                    <span className="">
                    {date?.joining_date ? 
                <>
                {formatDate(date?.joining_date)}
                </> 
                :
                 "--"}
                    </span>
                  </div>
                  <div className="flex items-center space-x-2">
                   
                    <span className="dark:text-white text-gray-500">
                      Date of Leaving:
                    </span>
                    <span className="">
                    {date?.leaving_date? 
                <>
                {formatDate(date?.leaving_date)}
                </> : "--"}
                    </span>
                  </div>
                </div>
                
              </div>
            </>
          )}
          </>
        ))}
          {/* History Data */}
        </div>
      </div>
    );
  }
}

export default OperatorDetails;








// {operatorDetail?.date_list?.map((date) => {
//   <>
//   <div key={date} className="flex flex-col gap-6 space-y-3 ">
    
    
//     {console.log(formatDate(date.joining_date))}
//   {date.leaving_date ? <>{console.log(formatDate(date.leaving_date))}</> : "--"}


  
//   </div>
//   </>
// })}