import React, {useState, useEffect} from 'react'
import { useSearchData } from './useSearchData';
import { useParams } from 'react-router-dom';
import { asset_by_type, asset_in_maintenance, get_all_assets, inactive_assets } from '../apis/AssetsApis/getAllAssets';

export const useSearchAssets = ({setShowCurrentTab}) => {
   
    const [assetDetails, setAssetDetails] = useState([]);
    const [loader, setLoader] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [itemOffset, setItemOffset] = useState(0);
    const [filter, setFilter] = useState("assetnumber");
    const [showAllData, setShowAllData] = useState(true);
    const [searchType, setSearchType] = useState("assetnumber");
    const [searchValue, setSearchValue] = useState("");
    const [searchTypeArray, setSearchTypeArray] = useState([]);
    const [showSearchDiv, setShowSearchDiv] = useState([]);
    const [getSearchList, setGetSearchList] = useState(false);
    const [items, setItems] = useState(1);
    const { query } = useParams();

        // console.log(filter)
    const {
      isPending: searchLoading,
      isError: isSearchError,
      data: searchData,
      error: searchError,
    } = useSearchData();

    const handleSearchCategory = () => {
     
        switch (filter) {
          case "assetnumber":
            setSearchType("assetnumber");
            let assetNumbers = searchData?.asset_data?.map((item) => item.asset_no);
            setSearchTypeArray(assetNumbers);
            break;
          case "category":
            setSearchType("category");
            setSearchTypeArray([...searchData.category]);
            break;
        
          case "yom":
            setSearchType("yom");
            setSearchTypeArray([...searchData.YOM]);
          default:
            break;
        }
      };




      const getAllAssets = async (searchData) => {
        try {
          let assetData = "";
          setLoader(true);
          if ((searchType !== "") | undefined && (searchData !== "") | null) {
            // console.log(searchData);
            const { data } = await asset_by_type(
              itemOffset,              
              searchType,
              searchData
            );
            // console.log(data);
            assetData = data;
          } else if (query == "inactive") {
            const { data } = await inactive_assets(itemOffset);
            assetData = data;
          } else if (query == "inMaintenance") {
            const { data } = await asset_in_maintenance(itemOffset);
            assetData = data;
          } else {
            const { data } = await get_all_assets(itemOffset);
            assetData = data;
          }
          if (assetData.message !== "valid token is missing")
            setAssetDetails(assetData);
            setLoader(false);
            setShowSearchDiv([]);
        } catch (error) {
          console.error(error);
          setLoader(false);
        }
      };
      function search(query) {
        if (query == "") {
          setShowSearchDiv([]);
          return;
        }
        const filteredData = searchTypeArray.filter((item) => {
          // console.log(item.length);
          return item.toLowerCase().includes(query.toLowerCase());
        });
        if (filteredData.length > 0) {
          setShowSearchDiv(filteredData);
        } else {
          setShowSearchDiv(["no results"]);
        }
    
        // console.log(filteredData);
      }
    
      const handleSearch = (e) => {
        search(e.target.value);
        setSearchValue(e.target.value);
      };
    
      const handleSearchClick = (item) => {
        // setGetSearchList(prev => !prev)
        // console.log(item);s
        // setSearchData(item)
    
        // getAllAssets(item);
        getAllAssets(item);
      };
    
      useEffect(() => {
        handleSearchCategory();
      }, [filter]);
      useEffect(() => {
        if (searchData) {
          setItems(searchData.asset_data.length);
        }
      }, [searchData]);
    
      useEffect(() => {
        getAllAssets();
      }, [setShowCurrentTab, deleted, itemOffset,getSearchList]);
      useEffect(() => {
        getAllAssets();
      }, [showAllData]);
      // this useEffect using for display search-element-box using setGetSearchList(false) and when update searchLoading sate
      useEffect(()=> {
        if (!searchLoading) setGetSearchList(false);
        handleSearchCategory();
      }, [searchLoading])


      return {
            setFilter:setFilter, 
            searchValue:searchValue, 
            handleSearch:handleSearch, 
            handleSearchClick:handleSearchClick,
            showSearchDiv:showSearchDiv,
            loader:loader,
            setLoader:setLoader,
            deleted:deleted,
            setDeleted:setDeleted,
            assetDetails:assetDetails,
            getAllAssets:getAllAssets,  
            showAllData:showAllData,
            getSearchList:getSearchList,
            itemOffset:itemOffset,
            setItemOffset:setItemOffset
        }
    
}


