import React, { useEffect, useState } from "react";
import { get_data_by_categories } from "../../../apis";

function CategoryTable() {
  
  const [unlinked_assets, setUnlinked_assets] = useState([]);
  const [Leased_assets, setLeased_assets] = useState([]);
  const [maintenance_assets, setMaintenance_assets] = useState([]);
  const [categories, setCaregories] = useState([])
  const [updateData, setUpdateData] = useState([])
  
  const getUnlinked_assets = async () => {
    try {
      const { data } = await get_data_by_categories();
      console.log(data);
      // setUpdateData(data)
      let dataArray = []
      for(let i=0; i < Object.keys(data).length; i++ ) {
        // console.log(Object.keys(data));
        
          // dataArray.push({`${Object.keys(data)[i]}`: "" })
          dataArray.push(`${Object.keys(data)[i]}`)
      }
        setCaregories((dataArray))
      let temp_unlinked_assets = [];
      let temp_leased_assets = [];
      let temp_maintenance_assets = [];
      data.forEach((item) => {
        for (const property in item) {
          temp_leased_assets.push(item[property].lease);
          temp_unlinked_assets.push(item[property].unlink_asset);
          temp_maintenance_assets.push(item[property].maintenance);
        }
      });
      setUnlinked_assets(temp_unlinked_assets);
      setLeased_assets(temp_leased_assets);
      setMaintenance_assets(temp_maintenance_assets);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getUnlinked_assets();
  }, []);

  // console.log(categories);
  

  return (
  <>

    <div className="p-5 mb-16">
      <div className=" text-center">
        <table className="w-full table-auto  text-xs">
            <thead className="border h-10 bg-[#efefef] dark:bg-black">
            <tr className="">
              <th className=" ">Category</th>
              <th className="">Inactive Assets</th>
              <th className="">Leased</th>
              <th className="">Under Breakdown</th>     
            </tr>
          </thead>


          <tbody className="">
            {categories?.map((item, index) => (
              <tr key={index} className="border">
                <td className="h-10">{item}</td>
                <td className="h-10">{unlinked_assets[index]}</td>
                <td className="h-10">{Leased_assets[index]}</td>
                <td className="h-10" >{maintenance_assets[index]}</td>
              
              </tr>
            ))}
          </tbody>
          </table>
      </div>
    </div>
  </>
  );
}

export default CategoryTable;
