import React, { useState } from "react";

function ConfigDetails({ assetConfigDetails, setAssetConfigDetails }) {
  const [open, setOpen] = useState(false);
  return (
    // <div class="flex animSlideup relative">
    //   <div class="intro-y basis-1/3 bg-white dark:bg-slate-900 rounded-lg mx-2 mt-5 shadow-md hover:shadow-xl">
    //     <div class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5">
    //       <h2 class="font-medium text-base mr-auto">Configuration Field</h2>
    //     </div>
    //     <div class="p-5 text-sm">
    //       <div class="flex flex-col">
    //         <div class="w-full">
    //           <div class="flex justify-between">
    //             <label>USED/NEW</label>
    //           </div>
    //           <select
    //             class="input w-full border mt-2 p-2 text-gray-500 border-slate-300 dark:bg-slate-900 font-medium rounded-md text-sm"
    //             value={assetConfigDetails?.used_or_new}
    //             onChange={(e) => setAssetConfigDetails({...assetConfigDetails, used_or_new: e.target.value})}
    //           >
    //             <option value="used">Used</option>
    //             <option value="new">New</option>
    //           </select>
    //         </div>
    //         <div class="w-full mt-2">
    //           <div class="flex justify-between">
    //             <label>ANSI/CE</label>
    //           </div>
    //           <select class="input w-full border mt-2 p-2 text-gray-500 border-slate-300 dark:bg-slate-900 font-medium rounded-md text-sm"
    //           value={assetConfigDetails?.ansi_or_new}
    //           onChange={(e) => setAssetConfigDetails({...assetConfigDetails, ansi_or_new: e.target.value})}
    //           >
    //             <option value="ansi">ANSI</option>
    //             <option value="ce">CE</option>
    //           </select>
    //         </div>
    //         <div class="w-full mt-2">

    //           <div class="flex justify-between">
    //             <label>Machine Ownership Ship Type</label>
    //           </div>
    //           <select class="input w-full border mt-2 p-2 text-gray-500 border-slate-300 dark:bg-slate-900 font-medium rounded-md text-sm"
    //           value={assetConfigDetails?.machine_ownership_type}
    //           onChange={(e) => setAssetConfigDetails({...assetConfigDetails, machine_ownership_type: e.target.value})}
    //           >
                // <option value="rental">Rental</option>
                // <option value="sands">S and S</option>
                // <option value="retail">Retail</option>
                // <option value="sold">Sold</option>
    //           </select>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   <div class="intro-y basis-1/3 bg-white dark:bg-slate-900 rounded-lg mx-2 mt-5 shadow-md hover:shadow-xl">
    //     <div class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5">
    //       <h2 class="font-medium text-base mr-auto py-3"></h2>
    //     </div>
    //     <div class="p-5 text-sm">
    //       <div class="w-full mt-0">
    //         <div class="flex justify-between">
    //           <label>DIESEL/BATTERY</label>
    //         </div>
    //         <select class="input w-full border mt-2 p-2 text-gray-500 border-slate-300 dark:bg-slate-900 font-medium rounded-md text-sm"
    //         value={assetConfigDetails?.battery_type}
    //         onChange={(e) => setAssetConfigDetails({...assetConfigDetails, battery_type: e.target.value})}
    //         >
              // <option value="diesel">Diesel</option>
              // <option value="battery">Battery</option>
    //         </select>
    //       </div>
    //       <div class="w-full mt-2">
    //         <label class="">ENGINE SR.NO</label>
    //         <input
    //           type="text"
    //           value={assetConfigDetails.engine_serial_no}
    //           onChange={(e) => setAssetConfigDetails({...assetConfigDetails, engine_serial_no: e.target.value})}
    //           placeholder="Engine sr.no"
    //           class="input w-full border mt-2 p-2 text-gray-500 border-slate-300 dark:bg-slate-900 font-medium rounded-md text-sms"
    //         />
    //       </div>
    //       <div class="w-full mt-2">
    //         <label class="">2WD / 4WD</label>
    //         <select class="input w-full border mt-2 p-2 text-gray-500 border-slate-300 dark:bg-slate-900 font-medium rounded-md text-sm"
    //         value={assetConfigDetails.two_or_four_wd}
    //         onChange={(e) => setAssetConfigDetails({...assetConfigDetails, two_or_four_wd: e.target.value})}
    //         >
              // <option value="2WD">2WD</option>
              // <option value="4WD">4WD</option>
    //         </select>
    //       </div>
    //     </div>
    //   </div>
    //   <div class="intro-y box basis-1/3 bg-white dark:bg-slate-900 rounded-lg mx-2 mt-5 shadow-md hover:shadow-xl">
    //     <div class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5">
    //       <h2 class=" font-medium  text-base mr-auto">
    //         Others
    //       </h2>
    //     </div>
    //     <div class="p-5 text-sm">
    //       <div class="flex flex-col">
    //         <div class="w-full">
    //           <label class="">Accessories (if any)</label>
    //           <input
    //             type="text"
    //             value={assetConfigDetails?.accessories}
    //             onChange={(e) => setAssetConfigDetails({...assetConfigDetails, accessories: e.target.value})}
    //             placeholder="Accessories"
    //             class="input w-full border p-2 border-slate-300 dark:bg-slate-900  font-medium rounded-md text-sm mt-2"
    //           />
    //         </div>

    //         <div class="w-full mt-3">
    //           <label class="">Tires</label>
    //           <select class="input w-full border p-2 text-gray-500 mt-2 border-slate-300 dark:bg-slate-900  font-medium rounded-md text-sm"
    //           value={assetConfigDetails.tyres}
    //           onChange={(e) => setAssetConfigDetails({...assetConfigDetails, tyres: e.target.value})}
    //           >
                // <option> Airfilled </option>
                // <option> Foam </option>
    //           </select>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <>
      <div className="my-5  ">
        <button
          className="bg-slate-950 text-white px-4 py-2 rounded-full text-xs"
          onClick={() => setOpen(!open)}
        >
          Configuration Details
        </button>
        {open && (
          <>
            <div className="border rounded-3xl p-10 mt-2 text-xs gap-4 bg-white dark:bg-slate-900 grid grid-cols-1 md:grid-cols-4 ">
              {/* Used or New */}
              <div className="flex-1 w-[200px] ">
                <label className="font-bold">Used/New</label>
                <select
                  type="text"
                  value={assetConfigDetails?.used_or_new}
                  placeholder="used or new"
                  class="w-[200px] h-8 rounded border-gray-200
                    focus:outline-none
                    focus:border-gray-200 focus:ring-0
                    text-xs"
                  onChange={(e) =>
                    setAssetConfigDetails({
                      ...assetConfigDetails,
                      used_or_new: e.target.value,
                    })
                  }
                >
                  <option value="used">Used</option>
                  <option value="new">New</option>
                </select>
              </div>
              {/* Ansi/ce */}
              <div className="flex-1 w-[200px] ">
                <label className="font-bold">ANSI/CE</label>
                <select
                  type="text"
                  value={assetConfigDetails?.ansi_or_new}
                  placeholder="ansi or new"
                  class="w-[200px] h-8 rounded border-gray-200
                    focus:outline-none
                    focus:border-gray-200 focus:ring-0
                    text-xs"
                  onChange={(e) =>
                    setAssetConfigDetails({
                      ...assetConfigDetails,
                      used_or_new: e.target.value,
                    })
                  }
                >
                  <option value="used">ANSI</option>
                  <option value="new">CE</option>
                </select>
              </div>
              {/* Ship Owner */}
              <div className="flex-1 w-[200px] ">
                <label className="font-bold">Machine Ownership Ship Type</label>
                <select
                  type="text"
                  value={assetConfigDetails?.machine_ownership_type}
              
                  class="w-[200px] h-8 rounded border-gray-200
                    focus:outline-none
                    focus:border-gray-200 focus:ring-0
                    text-xs"
                    onChange={(e) => setAssetConfigDetails({...assetConfigDetails, machine_ownership_type: e.target.value})}
                >
                 <option value="rental">Rental</option>
                <option value="sands">S and S</option>
                <option value="retail">Retail</option>
                <option value="sold">Sold</option>
                </select>
              </div>
              {/* DIESEL/BATTERY */}
              <div className="flex-1 w-[200px] ">
                <label className="font-bold">DIESEL/BATTERY</label>
                <select
                  type="text"
                  value={assetConfigDetails?.battery_type}
                 
                  class="w-[200px] h-8 rounded border-gray-200
                    focus:outline-none
                    focus:border-gray-200 focus:ring-0
                    text-xs"
                    onChange={(e) => setAssetConfigDetails({...assetConfigDetails, battery_type: e.target.value})}
                >
                <option value="diesel">Diesel</option>
                <option value="battery">Battery</option>
                </select>
              </div>
        {/* Engine Sr No */}
              <div className="flex-1 w-[200px] ">
                <label className="font-bold">ENGINE SR.NO</label>
                <select
                  type="text"
                  value={assetConfigDetails.engine_serial_no}
                  placeholder="Engine sr.no"
                  class="w-[200px] h-8 rounded border-gray-200
                    focus:outline-none
                    focus:border-gray-200 focus:ring-0
                    text-xs"
                    onChange={(e) => setAssetConfigDetails({...assetConfigDetails, engine_serial_no: e.target.value})}
                >
                <option value="diesel">Diesel</option>
                <option value="battery">Battery</option>
                </select>
              </div>
              {/* 2WD / 4WD */}
              <div className="flex-1 w-[200px] ">
                <label className="font-bold">2WD / 4WD</label>
                <select
                  type="text"
                  value={assetConfigDetails.two_or_four_wd}
                  class="w-[200px] h-8 rounded border-gray-200
                    focus:outline-none
                    focus:border-gray-200 focus:ring-0
                    text-xs"
                    onChange={(e) => setAssetConfigDetails({...assetConfigDetails, two_or_four_wd: e.target.value})}

                >
                <option value="2WD">2WD</option>
                <option value="4WD">4WD</option>
                </select>
              </div>
              {/* Others */}
              <div className="flex-1 w-[200px] ">
              <label className="font-bold">Accessories (if any)</label>
              <input
                type="text"
                value={assetConfigDetails?.accessories}
                
                placeholder="Accessories"
                class="w-[200px] h-8 rounded border-gray-200
                    focus:outline-none
                    focus:border-gray-200 focus:ring-0
                    text-xs"
                    onChange={(e) => setAssetConfigDetails({...assetConfigDetails, accessories: e.target.value})}

              />
            </div>
            {/* Tires */}
            <div className="flex-1 w-[200px] ">
                <label className="font-bold">Tires</label>
                <select
                  type="text"
                  value={assetConfigDetails.tyres}
                 
                  class="w-[200px] h-8 rounded border-gray-200
                    focus:outline-none
                    focus:border-gray-200 focus:ring-0
                    text-xs"
                    onChange={(e) => setAssetConfigDetails({...assetConfigDetails, tyres: e.target.value})}
                >
                  <option> Airfilled </option>
                  <option> Foam </option>
                </select>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default ConfigDetails;
