import React, { useContext, useEffect, useState } from 'react'
import { LoaderContext } from '../../../Context/LoaderContext'
import { setBaseUrl } from '../../../config';
import axios from 'axios';
import {CurrentItems} from "../../tables/LeaseTable/CurrentItems"
import { formatDate } from './Tabs/FormatDate';

function LeaseTab({asset_id}) {
  console.log(asset_id);
  
    const setLoader = useContext(LoaderContext)
    const [leaseDetails, setLeaseDetails] = useState([])
    const getAllLease = async () => {
        try {
          const { data } = await axios.get(`${setBaseUrl}/lease/get_all`, {
            headers: {
              "Content-Type": "application/json",
              "x-access-tokens": sessionStorage.getItem("token"),
              //   offset: itemOffset,
              //   limit: itemsPerPage,
              "asset-id": asset_id
            },
          });
          console.log(data);
          setLeaseDetails(data);
        } catch (error) {
          console.error(error);
        } 
      };
      useEffect(() => {
        getAllLease();
      },[])


  return (
    // <CurrentItems leaseDetails={leaseDetails} setLoader={setLoader}/>
    <>
      <div className=''>
        <table className='border fixed text-xs w-[182vh] text-center overflow-x-auto h-[200px] overflow-y-auto'>
          <thead className='bg-[#efefef] dark:bg-gray-700 h-10'>
            <tr>
              {/* <th>Asset No</th> */}
              <th>Odoo Order Id </th>
              <th>Lease Status</th>
              <th>Renatal Start Date</th>
              <th>Rental End Date</th>
              <th>Total Claimable Account</th>
              <th>Total Data</th>
            </tr>
          </thead>
          <tbody className=''>
            {leaseDetails.map((lease) => (
              <>
              
              <tr className='h-7'>
                <td className='border-b'>{lease?.odoo_order_id}</td>
                <td className={`border-b font-bold ${lease?.lease_status === 'inactive'? "text-slate-500": lease?.lease_status === 'active'? "text-green-500": lease?.lease_status === "never assinged" ? "text-red-600" : 'text-green-500'}`}>{lease?.lease_status}</td>
                <td className='border-b'>{formatDate(lease?.rental_start_date)}</td>
                <td className='border-b'>{lease?.rental_extended_date? `${formatDate(lease?.rental_extended_date)}`: `${formatDate(lease?.rental_end_date)}`}</td>
                <td className='border-b'>₹{parseInt(lease?.total_claimable_amount)}</td>
                <td className='border-b'>{lease?.total_data}</td>
            </tr>
              </>
            ))}
              
          </tbody>
        </table>

      </div>
    </>
  )
}

export default LeaseTab