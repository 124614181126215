import axios from "axios";
import React, { useEffect, useState } from "react";
import { setBaseUrl } from "../../../config";
import { useNavigate } from "react-router-dom";
import { PermissionContext } from "../../../Context/PermissionsContext";
import { useContext } from "react";
import {
  currency,
  customerGST,
  customerName,
  formatDate,
} from "../../Modals/AssetDetails/Tabs/FormatDate";

export const CurrentItems = ({
  
  leaseDetails,
  setLoader,
  deleted,
  setDeleted,
  selected,
  selectedItem,
  setSelectedItem,
  selectTableData,
}) => {
  const [perms] = useContext(PermissionContext);
  const navigate = useNavigate();
  const [tableAnimation, setTableAnimation] = useState(false);
  const deleteLease = async (id) => {
    console.log(id);
    try {
      setLoader(true);
      const { data } = axios.delete(
        `${setBaseUrl}/lease/delete`,

        {
          headers: {
            "Content-Type": "application/json",
            "x-access-tokens": sessionStorage.getItem("token"),
          },
          data: {
            id: id,
          },
        }
      );
      setDeleted(!deleted);
    } catch (error) {
      console.error(error);
      alert(error.message);
    } finally {
      setLoader(false);
      return;
    }
  };



  const handleClick = (id) => {
    navigate("/lease/leaseDetails/" + id);
  };

  const handleUpdate = (id) => {
    navigate("/lease/leaseUpdate/" + id);
  };
  useEffect(() => {
    setTimeout(() => {
      setTableAnimation(true);
    }, 400);
 
  }, [deleted]);

  



  return (
    // <div className="intro-y overflow-auto lg:overflow-y-scroll  sm:mt-0 h-[78vh]">
    // {leaseDetails === undefined || leaseDetails.length === 0 ? (
    //   <div className="h-[50vh] flex justify-center items-center">
    //     No Data available...
    //   </div>
    // ) : (
    //     <table
    //       className={`table ${
    //         tableAnimation ? "show-rows" : ""
    //       } cascade-slide border-separate border-spacing-y-3  sm:mt-2 w-full animSlideleft relative`}
    //     >
    //       <thead className="absolute top-0 bg-sky-100 h-16 shadow-lg z-50 dark:shadow-sm rounded-xl dark:bg-slate-600 ">
    //         <tr className="text-xs transition-transform  duration-300 ease-in-out transform dark:text-slate-50 font-semibold ">
    //           <th className="w-40 text-center whitespace-no-wrap px-5 py-3 font-medium rounded-l-lg ">
    //             Asset No.
    //           </th>
    //           <th className="w-52 whitespace-no-wrap  font-medium">
    //             Sales Person
    //           </th>
    //           {/* <th className="w-52 whitespace-no-wrap  font-medium">
    //               CUSTOMER PO NO
    //             </th> */}
    //           <th className="w-40 text-center whitespace-no-wrap px-5 py-3 font-medium">
    //             Odoo Order Id
    //           </th>
    //           <th className="w-40 text-center whitespace-no-wrap px-5 py-3 font-medium">
    //             Customer
    //           </th>

    //           <th className="w-60 text-center whitespace-no-wrap px-5 py-3 font-medium">
    //             Total Amount
    //           </th>
    //           <th className="w-56 text-center whitespace-no-wrap px-5 py-3 font-medium">
    //             Start Date
    //           </th>
    //           <th className="w-40 text-center whitespace-no-wrap px-5 py-3 font-medium">
    //             End Date
    //           </th>
    //           <th className="w-40 text-center whitespace-no-wrap px-5 py-3 font-medium">
    //             Status
    //           </th>
    //           <th className="w-72 text-center whitespace-no-wrap px-5 py-3 font-medium rounded-r-xl">
    //             Action
    //           </th>
    //         </tr>
    //       </thead>
    //       <tbody>
    //         {leaseDetails.map((item) => (
    //           <tr className="intro-x zoom-in text-xs rounded-lg bg-white dark:bg-gray-900 shadow-sm hover:shadow-xl text-gray-600 dark:text-slate-100">
    //             <td
    //               onClick={() => handleClick(item.id)}
    //               className="text-center py-3 rounded-l-xl "
    //             >
    //               {item.asset_no}
    //             </td>
    //             <td
    //               onClick={() => handleClick(item.id)}
    //               className="text-center "
    //             >
    //               {item?.sale_person}
    //             </td>
    //             <td
    //               onClick={() => handleClick(item.id)}
    //               className="text-center "
    //             >
    //               {item?.odoo_order_id===null? "Blank" : item.odoo_order_id}
    //             </td>

    //             <td
    //               onClick={() => handleClick(item.id)}
    //               className="text-center"
    //             >
    //               {item.customer}{" "}
    //             </td>
    //             <td
    //               onClick={() => handleClick(item.id)}
    //               className="text-center"
    //             >
    //               {" "}
    //               {item.total_claimable_amount}{" "}
    //             </td>
    //             <td
    //               onClick={() => handleClick(item.id)}
    //               className="text-center"
    //             >
    //               {" "}
    //               <span className="py-1 px-2 rounded text-xs bg-blue-700 text-white">
    //                 {" "}
    //                 {formatDate(item.rental_start_date)}
    //               </span>{" "}
    //             </td>
    //             <td
    //               onClick={() => handleClick(item.id)}
    //               className="text-center"
    //             >
    //               <span className="py-1 px-2 rounded text-xs bg-orange-400 text-white">
    //                 {" "}
    //                 {formatDate(item.rental_end_date)}
    //               </span>
    //             </td>
    //             <td className="">
    //               <div className="flex items-center justify-center">
    //                 {item.lease_status}
    //               </div>
    //             </td>
    //             <td className="table-report__action w-56 rounded-r-lg">
    //               <div className="flex justify-center items-center">
    // {(perms.indexOf("LEASE.ALL") !== -1 ||
    //   perms.indexOf("LEASE.CRU") !== -1 ||
    //   perms.indexOf("ADMIN.ALL") !== -1) && (
    //   <div
    //     onClick={() => handleUpdate(item.id)}
    //     className="flex items-center justify-center text-gray-500 dark:text-gray-400 cursor-pointer mr-4"
    //   >
    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   width="24px"
    //   height="24px"
    //   viewBox="0 0 24 24"
    //   fill="none"
    //   stroke="currentColor"
    //   strokeWidth="2"
    //   stroke-linecap="round"
    //   stroke-linejoin="round"
    //   className="w-4 h-4 mr-2 feather feather-edit"
    // >
    //   <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
    //   <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
    // </svg>{" "}
    //     Update
    //   </div>
    // )}
    // {(perms.indexOf("LEASE.ALL") !== -1 ||
    //   perms.indexOf("ADMIN.ALL") !== -1) && (
    //   <div
    //     onClick={() => deleteLease(item.id)}
    //     className="flex items-center justify-center cursor-pointer text-red-600"
    //   >
    //     <svg
    //       className="stroke-red-600 pr-1"
    //       xmlns="http://www.w3.org/2000/svg"
    //       width="23px"
    //       height="23px"
    //       viewBox="0 0 24 24"
    //       fill="none"
    //       stroke-width="2"
    //       stroke-linecap="round"
    //       stroke-linejoin="round"
    //     >
    //       <polyline points="3 6 5 6 21 6"></polyline>
    //       <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
    //     </svg>{" "}
    //     Delete
    //   </div>
    // )}
    //               </div>
    //             </td>
    //           </tr>
    //         ))}
    //       </tbody>
    //     </table>
    //   )}
    // </div>
    <>
      <div className="  w-full h-[calc(100vh-7.5rem)] my-4 overflow-hidden border hide-scrollbar rounded-xl max-lg:overflow-x-auto overflow-y-auto">
        {leaseDetails === undefined || leaseDetails.length === 0 ? (
          <div className="text-xs h-[50vh] flex rounded-lg justify-center items-center dark:text-white">
            No Data available...
          </div>
        ) : (
          <>
            <div className="">
              <table
                className={`table ${
                  tableAnimation ? "show-rows" : ""
                }  border-separate border-spacing-y-0 text-center text-xs w-full relative `}
              >
                <thead className="h-10 sticky bg-[#efefef] dark:bg-gray-700">
                  <tr className="">
                    <th>Asset No.</th>
                    {/* <th>Customer Po No.</th> */}
                    <th>Order Odoo Id</th>
                    <th>Customer</th>
                    <th>Total Amount</th>
                    <th>Start Date</th>
                    <th>End Date</th>     
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody className="bg-white dark:bg-gray-900 ">
                  {leaseDetails.map((item, index) => {
                    if (selectTableData >= index || !selectTableData) {
                      return (
                        <>
                          {selectedItem === "all Lease" ||
                          (`${item?.lease_status}`.indexOf(selectedItem) !==
                            -1) &
                            (selectedItem !== "active") ||
                          (item?.lease_status === "active" &&
                            selectedItem === "active") ? (
                            <>
                              <tr
                                className="cursor-pointer text-center items-center hover:bg-[#2222]  h-10 px-6"
                                onClick={() => handleClick(item.id)}
                                key={index}
                              >
                                <td className=" border-b">{item.asset_no}</td>
                                {/* <td 
                         className="border-b"
                         onClick={() => handleClick(item.id)}
                       >
                         {item?.sale_person}
                         </td> */}
                                <td
                                  className=" border-b"
                                  onClick={() => handleClick(item?.id)}
                                >
                                  {item?.odoo_order_id === null
                                    ? "--"
                                    : item?.odoo_order_id}
                                </td>

                                <td
                                  className="  border-b"
                                  onClick={() => handleClick(item?.id)}
                                >
                                  {item.customer}
                                </td>
                                <td
                                  className=" border-b"
                                  onClick={() => handleClick(item?.id)}
                                >
                                  ₹{currency(item?.total_claimable_amount)}
                                </td>
                                <td
                                  className=" border-b"
                                  onClick={() => handleClick(item.id)}
                                >
                                  {formatDate(item?.rental_start_date)}
                                </td>
                              
                                <td
                                  className=" border-b"
                                  onClick={() => handleClick(item.id)}
                                >
                                  {!item?.rental_extended_date ? (
                                    <>
                                    {formatDate(item?.rental_end_date)}
                                    </>
                                  ) : (
                                    <>
                                      {" "}
                                      {formatDate(
                                        item?.rental_extended_date?.split(
                                          " "
                                        )[0]
                                      )}
                                    </>
                                  )}
                                </td>

                                <td
                                  className={` border-b font-bold
                            ${
                              item?.lease_status === "never assigned"
                                ? "text-red-600"
                                : item?.lease_status === "inactive"
                                ? "text-slate-500"
                                : "text-green-500"
                            }
                           `}
                                  onClick={() => handleClick(item.id)}
                                >
                                  {" "}
                                  {item.lease_status}
                                </td>
                              </tr>
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      );
                    }
                  })}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </>
  );
};
