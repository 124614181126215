import React, { useState } from "react";
import Documents from "../../../InnerTabs/Documents";

const PhotoData = ({ imageUrl, NoImage}) => {
  const [openDoc, setOpenDoc] = useState(false);
  const [openPhoto, setOpenPhoto] = useState(false);
  
  return (
    // <div className="text-xs border my-2 rounded-3xl h-[400px]  bg-white dark:bg-slate-900 w-full">
     
    // <h1 className="text-base mt-3 font-bold  pl-5">Device Photos</h1>
      // {imageUrl.length > 0 ? (
      //   <>
      //     <div className=" max-w-sm  m-2 items-center flex justify-center">
      //       <img
      //         src={imageUrl[0]?.image_uri || NoImage[0]?.imgUrl}
      //         alt=""
      //         className="rounded-lg max-w-sm "
      //         width={350}
      //         height={200}
      //       />
            
      //     </div>
      //   </>
      // ) : (
      //   <>
      //     <div
      //       className=" max-w-sm  m-2 items-center flex justify-center
      //               "
      //     >
      //       <img
      //         src={NoImage[0].imgUrl}
      //         alt="Main Asset"
      //         width={350}
      //         height={200}
      //         className="rounded-lg max-w-sm"
      //       />
      //     </div>
      //   </>
      // )}

      
    //   <div className="flex justify-between items-center px-4 py-1">
    //     <button
    //       className="bg-slate-800 w-[100px] h-8 rounded-full text-white items-center text-center flex justify-center"
    //       onClick={() => setOpenDoc(!openDoc)}
    //     >
    //       Documents
    //     </button>
    //     {imageUrl.length > 0 && (
    //       <button
    //         onClick={() => setOpenPhoto(!openPhoto)}
    //         className="bg-slate-800 w-[100px] h-8 rounded-full text-white items-center text-center flex justify-center"
    //       >
    //         See Photos
    //       </button>
    //     )}
    //   </div>

    //   {/* {openDoc && (
    //     <>
    //     <div className="absolute top-[40%] left-[30%] bg-white z-[9999] dark:bg-slate-900 p-4 rounded-lg"  >
    //       <button className=" bg-slate-800 text-white p-2 mt-2 rounded-full"  onClick={() => setOpenDoc(false)}>Close</button>
    //       <Documents documentUrl={documentUrl} section="assets" />
    //     </div>
    //       <div className="fixed left-0 bg-opacity-50 bg-slate-800 z-0 h-screen w-screen top-0" />
    //     </>
    //   )} */}
    //   {openPhoto && (
    //     <>
    //       <div className="fixed top-[30%] left-[35%] bg-white dark:bg-slate-900 z-[9999] rounded-xl  ">
    //         <button className="mx-2 bg-slate-800 text-white p-2 mt-2 rounded-full" onClick={() => setOpenPhoto(false)}>Close</button>
    //           <div className="grid grid-cols-2 gap-2 mt-10 max-w-md  p-4 overflow-y-auto h-[320px] hide-scrollbar">
    //                       {imageUrl.slice(1).map((image, index) => (
    //                         <>
    //                         <div className="flex flex-col  bg-slate-100 shadow-lg dark:bg-slate-600 rounded-lg  ">
    //                       <img
    //                         key={index}
    //                         src={image.image_uri}
    //                         alt={`Thumbnail ${index + 1}`}
    //                         width={150}
    //                         height={150}
    //                         className="rounded-t-lg w-full"
    //                         />
                          
    //                         <h1 className="text-xs p-2 flex gap-1">Date:
    //                           <span>16-08-2024</span>
    //                         </h1>
                          
                         
    //                         </div>
    //                       </>
    //                       ))}
    //                     </div>
    //       </div>
    //        <div className="fixed left-0 bg-opacity-50 bg-slate-800 z-0 h-screen w-screen top-0" />
    //     </>
    //   )}
    // </div>
    <>
   <div className="border rounded-xl ">
   {imageUrl.length > 0 ? (
        <>
          <div className=" max-w-sm  m-2 items-center flex justify-center">
            <img
              src={imageUrl[0]?.image_uri || NoImage[0]?.imgUrl}
              alt=""
              className="rounded-lg max-w-sm "
              width={200}
              height={200}
            />
            
          </div>
        </>
      ) : (
        <>
          <div
            className=" max-w-sm  m-2 items-center flex justify-center
                    "
          >
            <img
              src={NoImage[0].imgUrl}
              alt="Main Asset"
              width={200}
              height={200}
            
              className="rounded-lg max-w-sm"
            />
          </div>
        </>
      )}
   </div>
    </>
  );
};

export default PhotoData;
