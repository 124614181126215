const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [day, month, year].join("-");
}

export const FormPercentage = (number1, number2) => {
  let result = (number1 / number2) * 100;
  return parseFloat(result).toFixed(2);
};

export function capitalizeFirstLetter(string) {
  return string?.charAt(0).toUpperCase() + string?.slice(1);
}

export const currency = (number) => {
  let floatNumber;


  try {
    if (number === ''){
        throw Error("Not a number")
    }
    floatNumber = parseFloat(number);
  } catch (error) {
    floatNumber = 0;
  }

  var  formatter = new Intl.NumberFormat("en-IN");
  var formattedNumber = formatter.format(floatNumber);
  if (formattedNumber.split(".").length <= 1) formattedNumber = formattedNumber + ".00"
  else if (formattedNumber.split(".")[1].length == 1) formattedNumber = formattedNumber + "0"
  // var formattedNumber = numbe
 
  
  return formattedNumber;
  // Output: 1,234,567.89
};

export const customerName = (name) => {
  const item_1 = name.split("-");
  let item2 = item_1[1];

  return item2;
};

export const customerGST = (name) => {
  const item_1 = name.split("-");
  let item2 = item_1[0];

  return item2;
};

export const formatAadhaar = (number) => {
  let aadhaarNumber = number.toString().replace(/\d{4}(?=\d)/g, "$& ");
  return aadhaarNumber;
};

export const formatDateForInput = (date) => {
  if (!date) return null;
  return new Date(date).toISOString().split("T")[0]; // Returns 'YYYY-MM-DD'
};

export const formatMonth = (number) => {
  let [year, month] = number.split("-");

  let formattedMonth = monthNames[parseInt(month, 10) - 1];
  return { formattedMonth: formattedMonth, year: year };
};
