import React from 'react'
import { capitalizeFirstLetter } from './FormatDate'

const AssetHeader = ({assetDetails}) => {

  return (
    <>
    <div className="grid grid-cols-4 items-center text-justify text-xs w-full  gap-5 justify-between border rounded-2xl pb-1 ">
      {/* Frame 1 */}
      <div className="flex-1 py-1 space-y-1 bg-white dark:bg-slate-900 px-4  h-fit mx-3 bord">
        <div className="py-2">
          <span className="text-gray-500">Machine No. - Model No.</span>
          <span className="block font-semibold text-xs">
          {assetDetails?.asset_no} - {assetDetails?.model} 
          </span>
        </div>
        <div className="py-2">
          <span className="text-gray-500">Make - Serial No.</span>
          <span className="block font-semibold text-xs">
          {assetDetails?.make} - {assetDetails?.serial_no}
          </span>
        </div>
      </div>

      {/* Frame 2 */}
      <div className="flex-1 py-1 space-y-1 bg-white dark:bg-slate-900 px-16  h-fit borde">
      
      
        <div className="py-2">
          <span className="text-gray-500">Category</span>
          <span className="block font-semibold text-xs">
            {assetDetails?.category}
          </span>
        </div>
        <div className="py-2">
          <span className="text-gray-500">Year of Manufacturing</span>
          <span className="block font-semibold text-xs">
            {assetDetails?.yom}
          </span>
        </div>
      </div>



      {/* Frame 4 */}
      <div className="flex-1 py-1 space-y-1 bg-white dark:bg-slate-900 px-24   h-fit borde">
      
      {/* <div className="py-2">
          <span className="text-gray-500">Location of Device</span>
          <span className="block font-semibold text-xs">
            {assetDetails?.site_location}
          </span>
        </div> */}
         <div className="py-2">
          <span className="text-gray-500">Lease Status</span>
          <span
            className={`block font-semibold text-xs ${
              assetDetails?.lease_status === "inactive"
                ? "text-slate-600"
                : assetDetails?.lease_status === 'active' ?  "text-green-500" :
                assetDetails?.lease_status === 'expired' ? 'text-red-600': 'text-blue-600' 
            }`}
          >
            {capitalizeFirstLetter(assetDetails?.lease_status)}
          </span>
        </div>
        <div className="py-2">
          <span className="text-gray-500">Capacity</span>
          <span className="block font-semibold text-xs">
            {assetDetails?.capacity}kg-{assetDetails?.hieght_mechine}ft
          </span>
        </div>
       
      </div>

     


      {/* Frame 7 */}
      <div className="flex-1 py-1 space-y-1 bg-white dark:bg-slate-900 px-20  h-fit mx-3 borde">
      
      
         <div className="py-2">
          <span className="text-gray-500">Tracker</span>
            <span className={`block font-semibold text-xs ${
                assetDetails?.device_id ? "text-blue-600" : "text-gray-500"
              }`}>
          {assetDetails?.device_id ? "Installed" : "Not Installed"}
          </span>
        </div>
        <div className="py-2">
          <span className="text-gray-500">Ratings</span>
          <span className="block font-bold text-xs text-red-600 ">
           {assetDetails?.rating? `${assetDetails?.rating}`: 5}/5
          </span>
        </div>
      </div>
       
    </div>
  </>
  )
}

export default AssetHeader
