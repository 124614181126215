import React, { useState } from "react";

function CommercialDetails({
  commToggle,
  setCommToggle,
  commercialDetails,
  setCommercialDetails,
}) {
  const handleToggle = () => {
    setCommToggle((prev) => !prev);
  };
  return (
    <>
      <div className="flex animSlideup relative">
        <div className="">
          <div className="flex items-center h-9 w-fit px-2 gap-3 bg-black text-white rounded-full dark:border-dark-5">
            <button className="font-medium text-xs mr-auto cursor-pointer" onClick={handleToggle}>
              Commercial Details
            </button>
            {/* <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
              <input
               
                type="checkbox"
                name="toggle"
                id="showComm"
                className="
              comToggle-checkbox 
              
              absolute block w-4 h-4 rounded-full bg-white  appearance-none transition-all duration-200 ease-in hover:scale-105 cursor-pointer"
              />
              <label
                for="showComm"
                className="comToggle-label block overflow-hidden h-4 rounded-full bg-gray-500 cursor-pointer"
              ></label>
            </div> */}
          </div>
        </div>
      </div>

      {/* {commToggle && <div id="" className="showOnComm p-5 text-sm">
            <div className="flex flex-col">
              <div className="w-full mt-2">
                <label className=""> PO No.and copy placed on OEM</label>
                <input
                  type="text"
                  value={commercialDetails?.purchase_order_no}
                  placeholder="PO No.and copy placed on OEM"
                  onChange={(e) => setCommercialDetails({...commercialDetails, purchase_order_no: e.target.value })}
                  className="input w-full border mt-2 p-2 text-gray-500 border-slate-300 dark:bg-slate-900 font-medium rounded-md text-sm"
                />
              </div>
              <div className="w-full mt-2">
                <label className=""> PO Date</label>
                <input
                  type="date"
                  value={commercialDetails?.purchase_order_date}
                  onChange={(e) => setCommercialDetails({...commercialDetails, purchase_order_date:e.target.value})}
                  className="input w-full border mt-2 p-2 text-gray-500 border-slate-300 dark:bg-slate-900 font-medium rounded-md text-sm"
                />
              </div>
              <div className="w-full mt-2">
                <label className=""> Invoice No.</label>
                <input
                  type="text"
                  placeholder="Invoice No."
                  value={commercialDetails?.invoice_no}
                  onChange={(e) => setCommercialDetails({...commercialDetails, invoice_no:e.target.value})}
                  className="input w-full border mt-2 p-2 text-gray-500 border-slate-300 dark:bg-slate-900 font-medium rounded-md text-sm"
                />
              </div>
              <div className="w-full mt-2">
                <label className=""> Invoice Date</label>
                <input
                  type="date"
                  value={commercialDetails?.invoice_date}
                  onChange={(e) => setCommercialDetails({...commercialDetails, invoice_date:e.target.value})}
                  className="input w-full border mt-2 p-2 border-slate-300 text-gray-500 dark:bg-slate-900 font-medium rounded-md text-sm"
                />
              </div>
              <div className="w-full mt-2">
                <label className=""> Payment Terms</label>
                <input
                  type="text"
                  value={commercialDetails?.payment_terms}
                  onChange={(e) => setCommercialDetails({...commercialDetails, payment_terms:e.target.value})}
                  placeholder="Payment Terms"
                  className="input w-full border mt-2 p-2 text-gray-500 border-slate-300 dark:bg-slate-900 font-medium rounded-md text-sm"
                />
              </div>
              <div className="w-full mt-2">
                <label className=""> Payment Remitted to OEM</label>
                <input
                  type="text"
                  value={commercialDetails?.amount_rem_to_oem}
                  onChange={(e) => setCommercialDetails({...commercialDetails, amount_rem_to_oem:e.target.value})}
                  placeholder="Payment Remitted to OEM"
                  className="input w-full border mt-2 p-2 text-gray-500 border-slate-300 dark:bg-slate-900 font-medium rounded-md text-sm"
                />
              </div>
              <div className="w-full mt-2">
                <label className=""> Date of Payment Remitted to OEM</label>
                <input
                  type="date"
                  value={commercialDetails?.date_of_rem_to_oem}
                  onChange={(e) => setCommercialDetails({...commercialDetails, date_of_rem_to_oem : e.target.value})}
                  className="input w-full border mt-2 p-2 text-gray-500 border-slate-300 dark:bg-slate-900 font-medium rounded-md text-sm"
                />
              </div>
              <div className="w-full mt-2">
                <label className=""> Exchange Rate of Remittence</label>
                <input
                  type="text"
                  value={commercialDetails?.exchange_rate_rem}
                  onChange={(e) => setCommercialDetails({...commercialDetails, exchange_rate_rem:e.target.value})}
                  placeholder="Exchange Rate of Remittence"
                  className="input w-full border mt-2 p-2 text-gray-500 border-slate-300 dark:bg-slate-900 font-medium rounded-md text-sm"
                />
              </div>
            </div>
          </div>} */}
      {/* {commToggle && <div className="showOnComm  intro-y  box basis-1/2 bg-white dark:bg-slate-900 rounded-lg mx-2 mt-5 shadow-md hover:shadow-xl">
          <div className="flex items-center p-5 border-b border-gray-200 dark:border-dark-5">
            <h2 className="font-medium text-base mr-auto">
              Commercial Details
            </h2>
          </div>
          <div className="p-5 text-sm">
            <div className="flex flex-col">
              <div className="w-full mt-2">
                <label className=""> Date of Custom Duty Payment</label>
                <input
                  type="date"
                  value={commercialDetails?.custom_duty_payment}
                  onChange={(e) => setCommercialDetails({...commercialDetails, custom_duty_payment:e.target.value})}
                  className="input w-full border mt-2 p-2 text-gray-500 border-slate-300 dark:bg-slate-900 font-medium rounded-md text-sm"
                />
              </div>
              <div className="w-full mt-2">
                <label className=""> Ex Work Price</label>
                <input
                  type="text"
                  value={commercialDetails?.exworks_price}
                  onChange={(e) => setCommercialDetails({...commercialDetails, exworks_price: e.target.value})}
                  placeholder="Ex Work Price"
                  className="input w-full border mt-2 p-2 text-gray-500 border-slate-300 dark:bg-slate-900 font-medium rounded-md text-sm"
                />
              </div>
              <div className="w-full mt-2">
                <label className=""> CIF Charges</label>
                <input
                  type="text"
                  value={commercialDetails?.cif_charges}
                  onChange={(e) => setCommercialDetails({...commercialDetails, cif_charges: e.target.value})}
                  placeholder="0"
                  className="input w-full border mt-2 p-2 text-gray-500 border-slate-300 dark:bg-slate-900 font-medium rounded-md text-sm"
                />
              </div>
              <div className="w-full mt-2">
                <label className=""> Total Cost</label>
                <input
                  type="text"
                  value={commercialDetails?.total_cost}
                  onChange={(e) => setCommercialDetails({...commercialDetails, total_cost: e.target.value})}
                  placeholder="0"
                  className="input w-full border mt-2 p-2 text-gray-500 border-slate-300 dark:bg-slate-900 font-medium rounded-md text-sm"
                />
              </div>
              <div className="w-full mt-2">
                <label className=""> BOE No.</label>
                <input
                  type="text"
                  value={commercialDetails?.boe_no}
                  onChange={(e) => setCommercialDetails({...commercialDetails, boe_no: e.target.value})}
                  placeholder="BOE No."
                  className="input w-full border mt-2 p-2 text-gray-500 border-slate-300 dark:bg-slate-900 font-medium rounded-md text-sm"
                />
              </div>
              <div className="w-full mt-2">
                <label className=""> Custom Duty Value</label>
                <input
                  type="text"
                  value={commercialDetails?.custom_duty_value}
                  onChange={(e) => setCommercialDetails({...commercialDetails, custom_duty_value: e.target.value})}
                  placeholder="Custom Duty Value"
                  className="input w-full border mt-2 p-2 text-gray-500 border-slate-300 dark:bg-slate-900 font-medium rounded-md text-sm"
                />
              </div>
              <div className="w-full mt-2">
                <label className=""> GST Amount</label>
                <input
                  type="text"
                  value={commercialDetails?.gst_amount}
                  onChange={(e) => setCommercialDetails({...commercialDetails, gst_amount: e.target.value})}
                  placeholder="GST Amount"
                  className="input w-full border mt-2 p-2 text-gray-500 border-slate-300 dark:bg-slate-900 font-medium rounded-md text-sm"
                />
              </div>
              <div className="w-full mt-2">
                <label className=""> Ex Rate as per BOE</label>
                <input
                  type="text"
                  value={commercialDetails?.exrate_boe}
                  onChange={(e) => setCommercialDetails({...commercialDetails, exrate_boe: e.target.value})}
                  placeholder="Ex Rate as per BOE"
                  className="input w-full border mt-2 p-2 text-gray-500 border-slate-300 dark:bg-slate-900 font-medium rounded-md text-sm"
                />
              </div>
            </div>
          </div>
        </div>} */}
      {/* {commToggle && <div className="showOnComm">
          <div className="flex ">
            <div className="intro-y box basis-1/3 bg-white dark:bg-slate-900 rounded-lg mx-2 mt-5 shadow-md hover:shadow-xl">
              <div className="p-5 text-sm">
                <div className="flex flex-col">
                  <div className="w-full mt-2">
                    <label className=""> Clearing Charges </label>
                    <input
                      type="text"
                      value={commercialDetails?.clearing_charges}
                      onChange={(e) => setCommercialDetails({...commercialDetails, clearing_charges: e.target.value})}
                      placeholder="Clearing Charges"
                      className="input w-full border mt-2 p-2 border-slate-300 dark:bg-slate-900 font-medium rounded-md text-sm"
                    />
                  </div>
                  <div className="w-full mt-2">
                    <label className=""> CHA Charges </label>
                    <input
                      type="text"
                      value={commercialDetails?.cha_charges}
                      onChange={(e) => setCommercialDetails({...commercialDetails, cha_charges: e.target.value})}
                      placeholder="CHA Charges"
                      className="input w-full border mt-2 p-2 border-slate-300 dark:bg-slate-900 font-medium rounded-md text-sm"
                    />
                  </div>
                  <div className="w-full mt-2">
                    <label className=""> Transportation charges upto yard </label>
                    <input
                      type="text"
                      value={commercialDetails?.transportation_charges}
                      onChange={(e) => setCommercialDetails({...commercialDetails, transportation_charges: e.target.value}) }
                      placeholder="Transportation charges upto yard"
                      className="input w-full border mt-2 p-2 border-slate-300 dark:bg-slate-900 font-medium rounded-md text-sm"
                    />
                  </div>
                  <div className="w-full mt-2">
                    <label className=""> Country / Port of dispatch </label>
                    <input
                      type="text"
                      value={commercialDetails?.port_of_dispatch}
                      onChange={(e) => setCommercialDetails({...commercialDetails, port_of_dispatch: e.target.value})}
                      placeholder="Country / Port of dispatch"
                      className="input w-full border mt-2 p-2 border-slate-300 dark:bg-slate-900 font-medium rounded-md text-sm"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="intro-y box basis-1/3 bg-white dark:bg-slate-900 rounded-lg mx-2 mt-5 shadow-md hover:shadow-xl">
              <div className="p-5 text-sm">
                <div className="flex flex-col">
                  <div className="w-full mt-2">
                    <label className=""> Port of clearance </label>
                    <input
                      type="text"
                      value={commercialDetails?.port_of_clearance}
                      onChange={(e) => setCommercialDetails({...commercialDetails, port_of_clearance: e.target.value})}
                      placeholder="Port of clearance"
                      className="input w-full border mt-2 p-2 border-slate-300 dark:bg-slate-900 font-medium rounded-md text-sm"
                    />
                  </div>
                  <div className="w-full mt-2">
                    <label className=""> Under which GST machine cleared </label>
                    <input
                      type="text"
                      placeholder="Under which GST machine cleared"
                      className="input w-full border mt-2 p-2 border-slate-300 dark:bg-slate-900 font-medium rounded-md text-sm"
                    />
                  </div>
                
              
                </div>
              </div>
            </div>
            <div className="intro-y box basis-1/3 bg-white dark:bg-slate-900 rounded-lg mx-2 mt-5 shadow-md hover:shadow-xl">
              <div className="p-5 text-sm">
                <div className="flex flex-col">
                  <div className="w-full mt-2">
                    <label className=""> Period of Insurance </label>
                    <input
                      type="text"
                      value={commercialDetails?.period_of_insurance}
                      onChange={(e) => setCommercialDetails({...commercialDetails, period_of_insurance: e.target.value})}
                      placeholder="Period of Insurance"
                      className="input w-full border mt-2 p-2 border-slate-300 dark:bg-slate-900 font-medium rounded-md text-sm"
                    />
                  </div>
                  <div className="w-full mt-2">
                    <label className=""> Renewal Date </label>
                    <input
                      type="date"
                      value={commercialDetails?.insurance_renewal}
                      onChange={(e) => setCommercialDetails({...commercialDetails, insurance_renewal: e.target.value})}
                      className="input w-full border mt-2 p-2 border-slate-300 dark:bg-slate-900 font-medium rounded-md text-sm"
                    />
                  </div>
                  <div className="w-full mt-2">
                    <label className=""> Total Landed Cost </label>
                    <input
                      type="text"
                      value={commercialDetails?.total_landed_cost}
                      onChange={(e) => setCommercialDetails({...commercialDetails, total_landed_cost: e.target.value})}
                      placeholder="Total Landed Cost"
                      className="input w-full border p-2 mt-2 border-slate-300 dark:bg-slate-900 font-medium rounded-md text-sm"
                    />
                  </div>
                  <div className="w-full mt-2">
                    <label className=""> Total Landed Cost + GST </label>
                    <input
                      type="text"
                      value={commercialDetails?.total_landed_cost_with_gst}
                      onChange={(e) => setCommercialDetails({...commercialDetails, total_landed_cost_with_gst: e.target.value})}
                      placeholder="Total Landed Cost + GST"
                      className="input w-full border mt-2 p-2 border-slate-300 dark:bg-slate-900 font-medium rounded-md text-sm"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>} */}

      {commToggle && (
        <>
          <div className="border rounded-3xl text-xs p-10 mt-2 gap-4 bg-white dark:bg-slate-900 grid grid-cols-1 md:grid-cols-4">
            {/* Item 1 */}
            <div className="flex-1 w-[200px] ">
              <label className="font-bold">PO No.and copy placed on OEM</label>
              <input
                type="text"
                value={commercialDetails?.purchase_order_no}
                placeholder="PO No.and copy placed on OEM"
                class="w-[200px] h-8 rounded border-gray-200
                focus:outline-none
                focus:border-gray-200 focus:ring-0
                text-xs"
                onChange={(e) =>
                  setCommercialDetails({
                    ...commercialDetails,
                    purchase_order_no: e.target.value,
                  })
                }
              />
            </div>
            <div className="flex-1 w-[200px] ">
              <label className="font-bold">PO Date</label>
              <input
                type="text"
                value={commercialDetails?.purchase_order_date}
                placeholder="PO No.and copy placed on OEM"
                class="w-[200px] h-8 rounded border-gray-200
                  focus:outline-none
                  focus:border-gray-200 focus:ring-0
                  text-xs"
                onChange={(e) =>
                  setCommercialDetails({
                    ...commercialDetails,
                    purchase_order_date: e.target.value,
                  })
                }
              />
            </div>
            {/* Item 3 */}
            <div className="flex-1 w-[200px] ">
              <label className="font-bold">Invoice No.</label>
              <input
                type="text"
                value={commercialDetails?.invoice_no}
                placeholder="Invoice No."
                class="w-[200px] h-8 rounded border-gray-200
                focus:outline-none
                focus:border-gray-200 focus:ring-0
                text-xs"
                onChange={(e) =>
                  setCommercialDetails({
                    ...commercialDetails,
                    invoice_no: e.target.value,
                  })
                }
              />
            </div>

            {/* Item 4 */}
            <div className="flex-1 w-[200px] ">
              <label className="font-bold">Invoice Date</label>
              <input
                type="date"
                value={commercialDetails?.invoice_date}
                placeholder="Invoice No."
                class="w-[200px] h-8 rounded border-gray-200
                focus:outline-none
                focus:border-gray-200 focus:ring-0
                text-xs"
                onChange={(e) => setCommercialDetails({...commercialDetails, invoice_date:e.target.value})}
              />
            </div>
            {/* Item 5 */}
            <div className="flex-1 w-[200px] ">
              <label className="font-bold">Payment Terms</label>
              <input
                type="text"
                value={commercialDetails?.payment_terms}
                placeholder="Payment Terms"
                class="w-[200px] h-8 rounded border-gray-200
                focus:outline-none
                focus:border-gray-200 focus:ring-0
                text-xs"
                onChange={(e) => setCommercialDetails({...commercialDetails, payment_terms:e.target.value})}
              />
            </div>
            {/* Item 6 */}
            <div className="flex-1 w-[200px] ">
              <label className="font-bold">Payment Remitted to OEM</label>
              <input
                type="text"
                value={commercialDetails?.amount_rem_to_oem}
                placeholder="Payment Remitted to OEM"
                class="w-[200px] h-8 rounded border-gray-200
                focus:outline-none
                focus:border-gray-200 focus:ring-0
                text-xs"
                onChange={(e) => setCommercialDetails({...commercialDetails, amount_rem_to_oem:e.target.value})}
              />
            </div>
            {/* Item 7 */}
            <div className="flex-1 w-[200px] ">
              <label className="font-bold">Date of Payment Remitted to OEM</label>
              <input
                type="date"
                value={commercialDetails?.date_of_rem_to_oem}
                placeholder="Payment Remitted to OEM"
                class="w-[200px] h-8 rounded border-gray-200
                focus:outline-none
                focus:border-gray-200 focus:ring-0
                text-xs"
                onChange={(e) => setCommercialDetails({...commercialDetails, date_of_rem_to_oem : e.target.value})}
              />
            </div>
                {/* Item 8 */}
                <div className="flex-1 w-[200px] ">
              <label className="font-bold">Exchange Rate of Remittence</label>
              <input
                type="text"
                value={commercialDetails?.exchange_rate_rem}
                placeholder="Exchange Rate of Remittence"
                class="w-[200px] h-8 rounded border-gray-200
                focus:outline-none
                focus:border-gray-200 focus:ring-0
                text-xs"
                onChange={(e) => setCommercialDetails({...commercialDetails, exchange_rate_rem:e.target.value})}
              />
            </div>
            {/* Item 9 */}
            <div className="flex-1 w-[200px] ">
              <label className="font-bold">Date of Custom Duty Payment</label>
              <input
                 type="date"
                 value={commercialDetails?.custom_duty_payment}
                placeholder="Exchange Rate of Remittence"
                class="w-[200px] h-8 rounded border-gray-200
                focus:outline-none
                focus:border-gray-200 focus:ring-0
                text-xs"
                onChange={(e) => setCommercialDetails({...commercialDetails, custom_duty_payment:e.target.value})}
              />
            </div>
            {/* Item 10 */}
            <div className="flex-1 w-[200px] ">
              <label className="font-bold">Ex Work Price</label>
              <input
                 type="text"
                 value={commercialDetails?.exworks_price}
                placeholder="Ex Work Price"
                class="w-[200px] h-8 rounded border-gray-200
                focus:outline-none
                focus:border-gray-200 focus:ring-0
                text-xs"
                onChange={(e) => setCommercialDetails({...commercialDetails, exworks_price: e.target.value})}
              />
            </div>
            {/* Item 11 */}
            <div className="flex-1 w-[200px] ">
              <label className="font-bold">CIF Charges</label>
              <input
                 type="text"
                 value={commercialDetails?.cif_charges}
                placeholder="0"
                class="w-[200px] h-8 rounded border-gray-200
                focus:outline-none
                focus:border-gray-200 focus:ring-0
                text-xs"

                onChange={(e) => setCommercialDetails({...commercialDetails, cif_charges: e.target.value})}
              />
            </div>
          {/* Item 12 */}
          <div className="flex-1 w-[200px] ">
              <label className="font-bold">Total Cost</label>
              <input
                 type="text"
                 value={commercialDetails?.total_cost}
                placeholder="0"
                class="w-[200px] h-8 rounded border-gray-200
                focus:outline-none
                focus:border-gray-200 focus:ring-0
                text-xs"

                onChange={(e) => setCommercialDetails({...commercialDetails, total_cost: e.target.value})}
              />
            </div>
            {/* Item 13 */}
            <div className="flex-1 w-[200px] ">
              <label className="font-bold">BOE No.</label>
              <input
                 type="text"
                 value={commercialDetails?.boe_no}
                 placeholder="BOE No."
                class="w-[200px] h-8 rounded border-gray-200
                focus:outline-none
                focus:border-gray-200 focus:ring-0
                text-xs"

                onChange={(e) => setCommercialDetails({...commercialDetails, boe_no: e.target.value})}
              />
            </div>
            {/* Item 14 */}
            <div className="flex-1 w-[200px] ">
              <label className="font-bold"> Custom Duty Value</label>
              <input
                 type="text"
                 value={commercialDetails?.custom_duty_value}
                 placeholder="BOE No."
                class="w-[200px] h-8 rounded border-gray-200
                focus:outline-none
                focus:border-gray-200 focus:ring-0
                text-xs"

                onChange={(e) => setCommercialDetails({...commercialDetails, custom_duty_value: e.target.value})}
              />
            </div>
            {/* Item 15 */}
            <div className="flex-1 w-[200px] ">
              <label className="font-bold">GST Amount</label>
              <input
                 type="text"
                 value={commercialDetails?.gst_amount}
                 placeholder="GST Amount"
                class="w-[200px] h-8 rounded border-gray-200
                focus:outline-none
                focus:border-gray-200 focus:ring-0
                text-xs"

                onChange={(e) => setCommercialDetails({...commercialDetails, gst_amount: e.target.value})}
              />
            </div>
            {/* Item 16 */}
            <div className="flex-1 w-[200px] ">
              <label className="font-bold">Ex Rate as per BOE</label>
              <input
                 type="text"
                 value={commercialDetails?.exrate_boe}
                placeholder="Ex Rate as per BOE"
                class="w-[200px] h-8 rounded border-gray-200
                focus:outline-none
                focus:border-gray-200 focus:ring-0
                text-xs"

                onChange={(e) => setCommercialDetails({...commercialDetails, exrate_boe: e.target.value})}
              />
            </div>
            {/* Item 17 */}
            <div className="flex-1 w-[200px] ">
              <label className="font-bold">Clearing Charges</label>
              <input
                 type="text"
                 value={commercialDetails?.clearing_charges}
                placeholder="Ex Rate as per BOE"
                class="w-[200px] h-8 rounded border-gray-200
                focus:outline-none
                focus:border-gray-200 focus:ring-0
                text-xs"

                onChange={(e) => setCommercialDetails({...commercialDetails, clearing_charges: e.target.value})}
              />
            </div>
            {/* Item 18 */}
            <div className="flex-1 w-[200px] ">
              <label className="font-bold">CHA Charges </label>
              <input
                 type="text"
                 value={commercialDetails?.cha_charges}
                 placeholder="CHA Charges"
                class="w-[200px] h-8 rounded border-gray-200
                focus:outline-none
                focus:border-gray-200 focus:ring-0
                text-xs"

                onChange={(e) => setCommercialDetails({...commercialDetails, cha_charges: e.target.value})}
              />
            </div>
            {/* Item 19 */}
            <div className="flex-1 w-[200px] ">
              <label className="font-bold">Transportation charges upto yard</label>
              <input
                 type="text"
                 value={commercialDetails?.transportation_charges}
                placeholder="Transportation charges upto yard"
                class="w-[200px] h-8 rounded border-gray-200
                focus:outline-none
                focus:border-gray-200 focus:ring-0
                text-xs"

                onChange={(e) => setCommercialDetails({...commercialDetails, transportation_charges: e.target.value}) }
              />
            </div>
            {/* Item 20 */}
            <div className="flex-1 w-[200px] ">
              <label className="font-bold">Country / Port of dispatch</label>
              <input
                 type="text"
                 value={commercialDetails?.port_of_dispatch}
                placeholder="Country / Port of dispatch"
                class="w-[200px] h-8 rounded border-gray-200
                focus:outline-none
                focus:border-gray-200 focus:ring-0
                text-xs"

                onChange={(e) => setCommercialDetails({...commercialDetails, port_of_dispatch: e.target.value})}
              />
            </div>
            {/* Item 21 */}
            <div className="flex-1 w-[200px] ">
              <label className="font-bold"> Port of clearance </label>
              <input
                 type="text"
                 value={commercialDetails?.port_of_clearance}
                placeholder=" Port of clearance"
                class="w-[200px] h-8 rounded border-gray-200
                focus:outline-none
                focus:border-gray-200 focus:ring-0
                text-xs"

                onChange={(e) => setCommercialDetails({...commercialDetails, port_of_clearance: e.target.value})}
              />
            </div>
            {/* Item 22 */}
            <div className="flex-1 w-[200px] ">
              <label className="font-bold">  Under which GST machine cleared  </label>
              <input
                 type="text"
                //  value={commercialDetails?.port_of_clearance}
                placeholder="Under which GST machine cleared"
                class="w-[200px] h-8 rounded border-gray-200
                focus:outline-none
                focus:border-gray-200 focus:ring-0
                text-xs"

                // onChange={(e) => setCommercialDetails({...commercialDetails, port_of_clearance: e.target.value})}
              />
            </div>
            {/* Item 23 */}
            <div className="flex-1 w-[200px] ">
              <label className="font-bold">  Period of Insurance </label>
              <input
                 type="text"
                value={commercialDetails?.period_of_insurance}
                placeholder=" Port of Insurance"
                class="w-[200px] h-8 rounded border-gray-200
                focus:outline-none
                focus:border-gray-200 focus:ring-0
                text-xs"

                onChange={(e) => setCommercialDetails({...commercialDetails, period_of_insurance: e.target.value})}
              />
            </div>
            {/* Item 24 */}
            <div className="flex-1 w-[200px] ">
              <label className="font-bold">Renewal Date </label>
              <input
                 type="text"
                 value={commercialDetails?.insurance_renewal}
                placeholder="Renewal Date"
                class="w-[200px] h-8 rounded border-gray-200
                focus:outline-none
                focus:border-gray-200 focus:ring-0
                text-xs"

                onChange={(e) => setCommercialDetails({...commercialDetails, insurance_renewal: e.target.value})}
              />
            </div>
            {/* Item 25 */}
            <div className="flex-1 w-[200px] ">
              <label className="font-bold">Total Landed Cost </label>
              <input
                 type="text"
                 value={commercialDetails?.total_landed_cost}
                placeholder="Total Landed Cost"
                class="w-[200px] h-8 rounded border-gray-200
                focus:outline-none
                focus:border-gray-200 focus:ring-0
                text-xs"

                onChange={(e) => setCommercialDetails({...commercialDetails, insurance_renewal: e.target.value})}
              />
            </div>
            {/* Item 25 */}
            <div className="flex-1 w-[200px] ">
              <label className="font-bold"> Total Landed Cost + GST  </label>
              <input
                 type="text"
                 value={commercialDetails?.total_landed_cost_with_gst}
                placeholder="Total Landed Cost + GST"
                class="w-[200px] h-8 rounded border-gray-200
                focus:outline-none
                focus:border-gray-200 focus:ring-0
                text-xs"

                onChange={(e) => setCommercialDetails({...commercialDetails, total_landed_cost_with_gst: e.target.value})}
              />
            </div>

          </div>
        </>
      )}
    </>
  );
}

export default CommercialDetails;

// <div className="p-10 mt-2 text-xs gap-4 bg-white dark:bg-slate-900 grid grid-cols-1 md:grid-cols-4 ">
// {/* Used or New */}
{
  /* <div className="flex-1 w-[200px] ">
    <label className="font-bold">Used/New</label>
    <select
      type="text"
      value={assetConfigDetails?.used_or_new}
      placeholder="used or new"
      class="w-[200px] h-8 rounded border-gray-200
        focus:outline-none
        focus:border-gray-200 focus:ring-0
        text-xs"
      onChange={(e) =>
        setAssetConfigDetails({
          ...assetConfigDetails,
          used_or_new: e.target.value,
        })
      }
    >
    <option value="used">Used</option>
    <option value="new">New</option>
  </select> */
}
// </div>
// {/* Ansi/ce */}
// <div className="flex-1 w-[200px] ">
//   <label className="font-bold">ANSI/CE</label>
//   <select
//     type="text"
//     value={assetConfigDetails?.ansi_or_new}
//     placeholder="ansi or new"
//     class="w-[200px] h-8 rounded border-gray-200
//       focus:outline-none
//       focus:border-gray-200 focus:ring-0
//       text-xs"
//     onChange={(e) =>
//       setAssetConfigDetails({
//         ...assetConfigDetails,
//         used_or_new: e.target.value,
//       })
//     }
//   >
//     <option value="used">ANSI</option>
//     <option value="new">CE</option>
//   </select>
// </div>
// {/* Ship Owner */}
// <div className="flex-1 w-[200px] ">
//   <label className="font-bold">Machine Ownership Ship Type</label>
//   <select
//     type="text"
//     value={assetConfigDetails?.machine_ownership_type}

//     class="w-[200px] h-8 rounded border-gray-200
//       focus:outline-none
//       focus:border-gray-200 focus:ring-0
//       text-xs"
//       onChange={(e) => setAssetConfigDetails({...assetConfigDetails, machine_ownership_type: e.target.value})}
//   >
//    <option value="rental">Rental</option>
//   <option value="sands">S and S</option>
//   <option value="retail">Retail</option>
//   <option value="sold">Sold</option>
//   </select>
// </div>
// {/* DIESEL/BATTERY */}
// <div className="flex-1 w-[200px] ">
//   <label className="font-bold">DIESEL/BATTERY</label>
//   <select
//     type="text"
//     value={assetConfigDetails?.battery_type}

//     class="w-[200px] h-8 rounded border-gray-200
//       focus:outline-none
//       focus:border-gray-200 focus:ring-0
//       text-xs"
//       onChange={(e) => setAssetConfigDetails({...assetConfigDetails, battery_type: e.target.value})}
//   >
//   <option value="diesel">Diesel</option>
//   <option value="battery">Battery</option>
//   </select>
// </div>
// {/* Engine Sr No */}
// <div className="flex-1 w-[200px] ">
//   <label className="font-bold">ENGINE SR.NO</label>
//   <select
//     type="text"
//     value={assetConfigDetails.engine_serial_no}
//     placeholder="Engine sr.no"
//     class="w-[200px] h-8 rounded border-gray-200
//       focus:outline-none
//       focus:border-gray-200 focus:ring-0
//       text-xs"
//       onChange={(e) => setAssetConfigDetails({...assetConfigDetails, engine_serial_no: e.target.value})}
//   >
//   <option value="diesel">Diesel</option>
//   <option value="battery">Battery</option>
//   </select>
// </div>
// {/* 2WD / 4WD */}
// <div className="flex-1 w-[200px] ">
//   <label className="font-bold">2WD / 4WD</label>
//   <select
//     type="text"
//     value={assetConfigDetails.two_or_four_wd}
//     class="w-[200px] h-8 rounded border-gray-200
//       focus:outline-none
//       focus:border-gray-200 focus:ring-0
//       text-xs"
//       onChange={(e) => setAssetConfigDetails({...assetConfigDetails, two_or_four_wd: e.target.value})}

//   >
//   <option value="2WD">2WD</option>
//   <option value="4WD">4WD</option>
//   </select>
// </div>
// {/* Others */}
// <div className="flex-1 w-[200px] ">
// <label className="font-bold">Accessories (if any)</label>
// <input
//   type="text"
//   value={assetConfigDetails?.accessories}

//   placeholder="Accessories"
//   class="w-[200px] h-8 rounded border-gray-200
//       focus:outline-none
//       focus:border-gray-200 focus:ring-0
//       text-xs"
//       onChange={(e) => setAssetConfigDetails({...assetConfigDetails, accessories: e.target.value})}

// />
// </div>
// {/* Tires */}
// <div className="flex-1 w-[200px] ">
//   <label className="font-bold">Tires</label>
//   <select
//     type="text"
//     value={assetConfigDetails.tyres}

//     class="w-[200px] h-8 rounded border-gray-200
//       focus:outline-none
//       focus:border-gray-200 focus:ring-0
//       text-xs"
//       onChange={(e) => setAssetConfigDetails({...assetConfigDetails, tyres: e.target.value})}
//   >
//     <option> Airfilled </option>
//     <option> Foam </option>
//   </select>
// </div>
// </div>
